import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { LayoutService, LayoutInitService } from "../../core";
import QFLayoutContent from "../../../../assets/js/layout/base/content";
import { QFUtil } from "../../../../assets/js/components/util";
import QFLayoutBrand from "../../../../assets/js/layout/base/brand";
import QFLayoutAside from "../../../../assets/js/layout/base/aside";
import QFLayoutAsideMenu from "../../../../assets/js/layout/base/aside-menu";
import QFLayoutAsideToggle from "../../../../assets/js/layout/base/aside-toggle";
import QFLayoutStickyCard from "../../../../assets/js/layout/base/sticky-card";
import QFLayoutStretchedCard from "../../../../assets/js/layout/base/stretched-card";
import QFLayoutHeader from "../../../../assets/js/layout/base/header";
import QFLayoutHeaderMenu from "../../../../assets/js/layout/base/header-menu";
import { Subscription, Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NewworkflowComponent } from "src/app/modals/newworkflow/newworkflow.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DatePipe } from "@angular/common";
import { MatSort } from "@angular/material/sort";
import { ConfirmdeleteComponent } from "src/app/modals/confirmdelete/confirmdelete.component";
import { ToastrService } from "ngx-toastr";
import { CopyworkflowComponent } from "src/app/modals/copyworkflow/copyworkflow.component";
import { WorkflowhistoryComponent } from "src/app/modals/workflowhistory/workflowhistory.component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { environment } from "src/environments/environment";
import { ServiceNowModalComponent } from "src/app/modals/service-now-modal/service-now-modal.component";

export interface FormsData {
  _id: string;
  form_id: string;
  form_name: string;
  workflowdata: any;
  stage: string;
  status: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  action: any;
}

@Component({
  selector: "app-servicenow",
  templateUrl: "./servicenow.component.html",
  styleUrls: ["./servicenow.component.scss"],
})
export class ServicenowComponent implements OnInit {
  addServiceNow: FormGroup;
  addSNTable: FormGroup;
  delSNTable: FormGroup;
  form_id: any = 0;
  headerLogo: string;
  selfLayout = "default";
  asideCSSClasses: string;
  headerCSSClasses: string;
  contentClasses = "";
  contentExtended: false;
  contentContainerClasses = "";
  asideSelfMinimizeToggle = false;
  disableAsideSelfDisplay: boolean;
  brandSkin: string;
  asideMenuCSSClasses: string;
  asideMenuScroll = 1;
  asideMenuDropdown;
  ulCSSClasses: string;
  brandClasses: string;
  headerContainerCSSClasses: string;
  headerMenuSelfDisplay: boolean;
  asideSelfDisplay: boolean;
  headerMenuHTMLAttributes: any = {};
  asideHTMLAttributes: any = {};
  headerMobileAttributes = {};
  headerHTMLAttributes: any = {};
  asideMenuHTMLAttributes: any = {};
  publisheddata: any[] = [];
  checkedoutdata: any[] = [];
  confirmFormDeleteDialogRef = null;
  uname: any;
  uaddress: any;
  uemail: any;
  uphone: any;
  qformid: any;
  activeTabId = 1;
  workdata: any[] = [];
  rechistory: any[] = [];
  chkpubCheckOut: any = [];
  servicenowdata: any = [];
  snowdata: any;
  talbename: any;
  tablecolumns: any;
  apiurl: any;
  apiheaders: any;
  apicolumns: any;
  data: any;
  fileName = "";
  encfileName = "";
  isupload: boolean = false;
  recordid: any = "";
  snfile: any;
  arrheaders: any;
  snauth: any;
  sysid: any;
  editdetails: any;
  dialogRef: any;

  @ViewChild("ktHeaderMenu", { static: true }) ktHeaderMenu: ElementRef;
  @ViewChild("ktAside", { static: true }) ktAside: ElementRef;
  @ViewChild("ktHeaderMobile", { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild("ktHeader", { static: true }) ktHeader: ElementRef;
  loader$: Observable<number>;

  private loaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  private unsubscribe: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private initService: LayoutInitService,
    private layout: LayoutService,
    public api: WorkFlowService,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private fb: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.initService.init();

    this.addServiceNow = fb.group({
      uname: new FormControl(),
      uaddress: new FormControl(),
      uemail: new FormControl(),
      uphone: new FormControl(),
    });
    this.addSNTable = fb.group({
      tablename: new FormControl(),
      tablecolumns: new FormControl(),
    });
    this.delSNTable = fb.group({
      tablename: new FormControl(),
    });
  }

  ngOnInit() {
    this.selfLayout = this.layout.getProp("self.layout");
    this.brandClasses = this.layout.getProp("brand");
    this.asideCSSClasses = this.layout.getStringCSSClasses("aside");
    this.headerCSSClasses = this.layout.getStringCSSClasses("header");
    this.contentClasses = this.layout.getStringCSSClasses("content");
    this.contentExtended = this.layout.getProp("content.extended");
    this.contentContainerClasses =
      this.layout.getStringCSSClasses("content_container");
    this.headerLogo = this.getLogoURL();
    this.disableAsideSelfDisplay =
      this.layout.getProp("aside.self.display") === false;
    this.brandSkin = this.layout.getProp("brand.self.theme");
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
    this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
    this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown")
      ? "1"
      : "0";
    this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
    this.headerContainerCSSClasses =
      this.layout.getStringCSSClasses("header_container");
    this.headerMenuSelfDisplay = this.layout.getProp(
      "header.menu.self.display"
    );
    this.asideSelfDisplay = this.layout.getProp("aside.self.display");
    this.asideHTMLAttributes = this.layout.getHTMLAttributes("aside");
    this.headerMobileAttributes =
      this.layout.getHTMLAttributes("header_mobile");
    this.headerHTMLAttributes = this.layout.getHTMLAttributes("header");
    this.asideSelfMinimizeToggle = this.layout.getProp(
      "aside.self.minimize.toggle"
    );

    this.getCredentials();
  }

  private getLogoURL(): string {
    let result = "logo.png";
    return `../../../../assets/media/logos/${result}`;
  }

  getCredentials() {
    let workflowid = "61a709351c3a0000340022e3";
    let nodeid = "node-12";
    this.api.getRunScriptDetails(workflowid, nodeid).subscribe((res: any) => {
      this.apiurl = res.runscript_data[0].apiurl;
      this.apiheaders = res.runscript_data[0].apiheaders;
      this.apicolumns = res.runscript_data[0].apicolumns;
      this.getData(this.apiurl, this.apiheaders);
    });
  }

  getData(apiurl, apiheader) {
    apiheader = apiheader.replace(/(\r\n|\n|\r)/gm, "");
    this.arrheaders = JSON.parse(apiheader);

    this.snauth = this.arrheaders.Authorization;
    this.servicenowdata = [];
    this.api.getServiceNow(apiurl, apiheader).subscribe(
      (res: any) => {
        res.result.forEach((element) => {
          this.servicenowdata.push(element);
        });
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }

  genExternalId(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    this.snfile = File = event.target.files[0];

    if (file) {
      this.isupload = true;
      this.fileName = file.name;
    }
  }

  saveData() {
    let arrcolumns = this.apicolumns.split(",");
    const snowdata = {};

    arrcolumns.forEach((element) => {
      if (element == "u_first_name") {
        snowdata[element] = this.addServiceNow.value.uname;
      }
      if (element == "u_last_name") {
        snowdata[element] = this.addServiceNow.value.uaddress;
      }
      if (element == "u_email") {
        snowdata[element] = this.addServiceNow.value.uemail;
      }
      if (element == "u_phone_number") {
        snowdata[element] = this.addServiceNow.value.uphone;
      }
      if (element == "u_external_id") {
        snowdata[element] = this.genExternalId(24);
      }
    });

    this.api.saveServiceNow(this.apiurl, this.apiheaders, snowdata).subscribe(
      (res: any) => {
        this.ngxService.stop();

        if (res.result) {
          this.recordid = res.result.sys_id;
          if (this.isupload) {
            this.uploadDoc();
          }
          this.toastr.success(
            "ServiceNow Record saved successfully",
            "Success"
          );
          this.getData(this.apiurl, this.apiheaders);
        } else {
          this.toastr.error("Saving ServiceNow failed! ", "Error!");
        }

        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  EditData(sysid) {
    let apiurl = this.apiurl + "/" + sysid;
    let apiheader = this.apiheaders.replace(/(\r\n|\n|\r)/gm, "");
    this.api.getServiceNow(apiurl, apiheader).subscribe(
      (res: any) => {
        this.editdetails = res.result;
        this.EditDialog(this.editdetails);
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }
  EditRecord(externalid) {
    this.genSysId(externalid, "Edit");
  }

  EditDialog(editdetails) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.minHeight = "calc(100vh - 90px)";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      sysid: editdetails.sys_id,
      uname: editdetails.u_first_name,
      uaddress: editdetails.u_last_name,
      uphone: editdetails.u_phone_number,
      uemail: editdetails.u_email,
      apiurl: this.apiurl,
      apiheader: this.apiheaders,
      apicolumns: this.apicolumns,
    };
    this.dialogRef = this.dialog.open(ServiceNowModalComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      (query) => {
        if (typeof query !== "undefined") {
          this.getData(this.apiurl, this.apiheaders);
        }
      },
      (error) => {
        console.error("Error while closing the dialog ", error);
      }
    );
  }

  uploadDoc() {
    let apiurl =
      "https://" +
      environment.servicenow_instanceid +
      ".service-now.com/api/now/attachment/file?table_name=" +
      environment.servicenow_tablename +
      "&table_sys_id=" +
      this.recordid +
      "&file_name=" +
      this.fileName;
    let apiheader =
      '{"Accept":"application/json, text/plain, */*","Content-Type":"application/pdf, */*","Authorization":"' +
      this.snauth +
      '"}';

    this.api.saveServiceNow(apiurl, apiheader, this.snfile).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res.result) {
          this.toastr.success("Document Uploaded successfully", "Success");
        } else {
          this.toastr.error("Document Upload failed! ", "Error!");
        }

        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  saveTable() {
    const tabledata = {
      tablename: this.addSNTable.value.tablename,
      tablecolumns: this.addSNTable.value.tablecolumns,
    };
    this.api.saveServiceNowTable(tabledata).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res == "" || res == null) {
          this.toastr.success(
            "ServiceNow Table created successfully",
            "Success"
          );
          //this.getData();
        } else {
          this.toastr.error("Saving ServiceNow Table failed! ", "Error!");
        }

        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  deleteTable() {
    const deltabledata = {
      tablename: this.delSNTable.value.tablename,
    };
    this.api.deleteServiceNowTable(deltabledata).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res == "" || res == null) {
          this.toastr.success(
            "ServiceNow Table deleted successfully",
            "Success"
          );
          //this.getData();
        } else {
          this.toastr.error("Deleting ServiceNow Table failed! ", "Error!");
        }

        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  genSysId(externalid, funmode) {
    let apiurl = this.apiurl + "?sysparm_query=u_external_id=" + externalid;
    let apiheader = this.apiheaders.replace(/(\r\n|\n|\r)/gm, "");
    this.api.getServiceNow(apiurl, apiheader).subscribe(
      (res: any) => {
        res.result.forEach((element) => {
          this.sysid = element.sys_id;
          this.setFunction(element.sys_id, funmode);
        });
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }

  setFunction(sysid, funmode) {
    funmode == "delete" ? this.deleteData(sysid) : this.EditData(sysid);
  }

  deleteRecord(externalid) {
    this.genSysId(externalid, "delete");
  }

  deleteData(sysid) {
    this.api.deleteServiceNow(sysid).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res == "" || res == null) {
          this.toastr.success(
            "ServiceNow Record Deleted successfully",
            "Success"
          );
          this.getData(this.apiurl, this.apiheaders);
        } else {
          this.toastr.error("Deleting ServiceNow failed! ", "Error!");
        }

        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  ngAfterViewInit() {
    if (this.ktHeaderMenu) {
      for (const key in this.headerMenuHTMLAttributes) {
        if (this.headerMenuHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeaderMenu.nativeElement.attributes[key] =
            this.headerMenuHTMLAttributes[key];
        }
      }
    }

    if (this.ktAside) {
      for (const key in this.asideHTMLAttributes) {
        if (this.asideHTMLAttributes.hasOwnProperty(key)) {
          this.ktAside.nativeElement.attributes[key] =
            this.asideHTMLAttributes[key];
        }
      }
    }

    if (this.ktHeaderMobile) {
      for (const key in this.headerMobileAttributes) {
        if (this.headerMobileAttributes.hasOwnProperty(key)) {
          this.ktHeaderMobile.nativeElement.attributes[key] =
            this.headerMobileAttributes[key];
        }
      }
    }

    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] =
            this.headerHTMLAttributes[key];
        }
      }
    }
    QFLayoutContent.init("kt_content");
    QFUtil.ready(() => {
      QFLayoutHeader.init("kt_header", "kt_header_mobile");
      QFLayoutHeaderMenu.init("kt_header_menu", "kt_header_menu_wrapper");
      QFLayoutBrand.init("kt_brand");
      QFLayoutAside.init("kt_aside");
      QFLayoutAsideMenu.init("kt_aside_menu");
      if (this.asideSelfMinimizeToggle) {
        QFLayoutAsideToggle.init("kt_aside_toggle");
      }
      QFLayoutStickyCard.init("kt_page_sticky_card");
      QFLayoutStretchedCard.init("kt_page_stretched_card");
    });
  }
}
