/* eslint-disable */
"use strict";

import QFToggle from "./../../components/toggle.js";
import { QFUtil } from "./../../components/util.js";

var QFLayoutHeaderTopbar = function() {
    // Private properties
	var _toggleElement;
    var _toggleObject;

    // Private functions
    var _init = function() {
        _toggleObject = new QFToggle(_toggleElement, QFUtil.getBody(), {
            targetState: 'topbar-mobile-on',
            toggleState: 'active',
        });
    }

    // Public methods
	return {
		init: function(id) {
            _toggleElement = QFUtil.getById(id);

			if (!_toggleElement) {
                return;
            }

            // Initialize
            _init();
		},

        getToggleElement: function() {
            return _toggleElement;
        }
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = QFLayoutHeaderTopbar;
}

export default QFLayoutHeaderTopbar;