import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-integrationhub",
  templateUrl: "./integrationhub.component.html",
  styleUrls: ["./integrationhub.component.scss"],
})
export class IntegrationhubComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  workflowname: string;
  workflowstage: string;
  panelOpenState: boolean = false;
  ruleid = 0;
  begindetails: any;
  data: any;
  ispublished: boolean = false;
  formname: string;
  hubData: any = [];
  configList: any = [];
  Object = Object;
  selectedHub: any = [];
  hubObjectIds: any = [];

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public router: Router,
    private dialogRef: MatDialogRef<IntegrationhubComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      nodeid,
      nodetype,
      workflowname,
      workflowstage,
      hubObjectIds,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.hubObjectIds = hubObjectIds;
    this.formname = localStorage.getItem("formname");
    this.ispublished = ispublished;

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      workflowname: [workflowname],
      workflowstage: [workflowstage],
      hubObjectIds: [hubObjectIds],
      ispublished: [ispublished],
    });
    this.form.controls.workflowstage.disable();
  }

  ngOnInit(): void {
    this.hubData = this.api.objHubData;
    this.configList = this.api.iHubConfigList;
    if (this.description === "integrationhub") {
      this.description = "Integration Hub";
    }
    if (this.beginid !== 0) {
      this.hubObjectIds.forEach((element) => {
        this.selectedHub.push(element);
      });
      this.hubData.forEach((element) => {
        element["isChecked"] = false;
        this.selectedHub.forEach((elmChild) => {
          if (elmChild == element._id) element["isChecked"] = true;
        });
      });
    }
  }

  hasColumns(item) {
    let chkresult = false;
    this.hubData.forEach((element) => {
      if (element.accountName == item.hubName) chkresult = true;
    });
    return chkresult;
  }

  onHubSelection(evt) {
    if (evt.target.checked) {
      this.selectedHub.push(evt.target.value);
    } else {
      const index = this.selectedHub.findIndex((x) => x === evt.target.value);
      this.selectedHub.splice(index, 1);
    }
    console.log(this.selectedHub);
  }

  saveform(rulename, formstate) {
    let validform = true;
    if (this.form.value.workflowname == "") {
      this.toastr.error("Enter Integration Name!", "Error!");
      validform = false;
    }

    if (this.selectedHub.length == 0) {
      this.toastr.error("Select your options", "Error!");
      validform = false;
    }

    if (rulename == "integrationhub" && validform) {
      if (this.beginid === 0 && formstate == "save") {
        this.data = {
          workflowid: this.form.value.workflowid,
          formid: this.form.value.formid,
          nodeid: this.form.value.nodeid,
          nodetype: this.nodetype,
          formname: this.form.value.workflowname,
          stage: this.form.value.workflowstage,
          status: true,
          userid: this.api.getUserId(),
          hubObjectId: this.selectedHub,
        };
      } else {
        this.data = {
          beginid: this.beginid,
          workflowid: this.form.value.workflowid,
          formid: this.form.value.formid,
          nodeid: this.form.value.nodeid,
          nodetype: this.nodetype,
          formname: this.form.value.workflowname,
          stage: this.form.value.workflowstage,
          status: true,
          userid: this.api.getUserId(),
          hubObjectId: this.selectedHub,
        };
      }

      if (this.beginid === 0) {
        //save
        this.api.saveIntegrationHub(this.data).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success(
                "Integration Hub saved successfully",
                "Success"
              );
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Integration Hub failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      } else {
        //update
        this.api.updateIntegrationHub(this.data, this.data.beginid).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success(
                "Integration Hub Updated successfully",
                "Success"
              );
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Integration Hub failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      }
      this.dialogRef.close(this.form.value);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
