/* eslint-disable */
"use strict";

import { QFUtil } from "./../../components/util.js";
import QFOffcanvas from "./../../components/offcanvas.js";

var QFLayoutAside = function() {
    // Private properties
    var _body;
    var _element;
    var _offcanvasObject;

    // Private functions
	// Initialize
	var _init = function() {
		var offcanvasClass = QFUtil.hasClass(_element, 'aside-offcanvas-default') ? 'aside-offcanvas-default' : 'aside';

        // Initialize mobile aside offcanvas
		_offcanvasObject = new QFOffcanvas(_element, {
			baseClass: offcanvasClass,
			overlay: true,
			closeBy: 'kt_aside_close_btn',
			toggleBy: {
				target: 'kt_aside_mobile_toggle',
				state: 'mobile-toggle-active'
			}
		});
	}

    // Public methods
	return {
		init: function(id) {
            _element = QFUtil.getById(id);
            _body = QFUtil.getBody();

            if (!_element) {
                return;
            }

            // Initialize
            _init();
        },

        getElement: function() {
            return _element;
        },

        getOffcanvas: function() {
            return _offcanvasObject;
        },

        isFixed: function() {
            return QFUtil.hasClass(_body, 'aside-fixed');
        },

        isMinimized: function() {
            return (QFUtil.hasClass(_body, 'aside-fixed') && QFUtil.hasClass(_body, 'aside-minimize'));
        },

        isHoverable: function() {
            return (QFUtil.hasClass(_body, 'aside-fixed') && QFUtil.hasClass(_body, 'aside-minimize-hoverable'));
        }
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = QFLayoutAside;
}

export default QFLayoutAside;