import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import * as moment from "moment";
import $ from "jquery";
import Tagify from "../../../assets/js/tagify.min";
import DatePicker from "../../../assets/plugins/qforms/bootstrap-datepicker";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatRadioChange } from "@angular/material/radio";

declare function activateDatepicker(): any;

@Component({
  selector: "app-conditionalbranch",
  templateUrl: "./conditionalbranch.component.html",
  styleUrls: ["./conditionalbranch.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConditionalbranchComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  workflowname: string;
  workflowstage: string;
  slcand: any = [];
  rule: any = [];
  updateValue: any = [];
  andArray: any = [];
  panelOpenState: boolean = true;
  cloneCount: any;
  childcount: any;
  data: any;
  formControls: any = [];
  applicableStates: any = [];
  bindslcand: any;
  bindsubCtrl: any;
  applicablestate: any = [];
  possibleValues = [];
  subControls = [];
  upddropdown: string = "";
  updcount = 1;
  conditionsValue = {
    text: false,
    number: false,
    dropdown: false,
    date: false,
    time: false,
  };
  hasSubControls: boolean = false;
  conditions: {
    control: number;
    type: string;
    state: string;
    value: any;
    isValid: boolean;
    conditionsValue: any;
    hasSubControls: boolean;
  }[] = [];
  arrsubcontrols: any = [];
  ispublished: boolean = false;
  formname: string;
  branchid: any = 0;

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private dialogRef: MatDialogRef<ConditionalbranchComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      nodeid,
      nodetype,
      workflowname,
      workflowstage,
      slcand,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.slcand = slcand;
    this.ispublished = ispublished;
    this.formname = localStorage.getItem("formname");

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      workflowname: [workflowname, Validators.required],
      workflowstage: [workflowstage],
      slcand: [slcand],
      ispublished: [ispublished],
    });

    //if (this.workflowstage != "") {
    this.form.controls.workflowstage.disable();
    //}

    this.cloneCount = 2;
    this.childcount = 1000;
    this.getControls();
  }

  ngOnInit(): void {
    if (this.description === "conditionalbranch") {
      this.description = "Conditional Branch";
    }

    const initConditions = [
      {
        control: 0,
        type: "",
        state: "",
        value: null,
        isValid: true,
        conditionsValue: {},
        hasSubControls: false,
      },
    ];

    this.conditions = initConditions;

    if (this.beginid == 0) {
      this.branchid = 1;
    }

    if (this.beginid != 0) {
      this.bindTypesOnUpdate();
      activateDatepicker();
      let parentid = 0;
      let chkcondand = false;
      let chkcondor = false;

      for (let x = 0; x < this.slcand.length; x++) {
        if (this.slcand[x][1].conditions.state == "and") {
          parentid += 1;
          chkcondand = true;
          chkcondor = false;
          this.branchid += 1;
        }
        if (this.slcand[x][1].conditions.state == "or") {
          parentid += 1;
          chkcondor = true;
          chkcondand = false;
        }
        if (this.slcand[x][1].conditions.subcontrol) {
          this.arrsubcontrols = this.slcand[x][1].conditions.subcontrol;
        } else {
          this.arrsubcontrols = false;
        }
        if (chkcondand) {
          this.updateValue.push({
            condition_id: this.slcand[x][1].conditions.condition_id,
            control_id: this.slcand[x][1].conditions.control_id,
            condition_type: this.slcand[x][1].conditions.condition_type,
            control: this.slcand[x][1].conditions.control,
            subcontrol: this.arrsubcontrols,
            state: this.slcand[x][1].conditions.state,
            value: this.slcand[x][1].conditions.value,
            dataid: "clone-box-and-" + parentid,
            dataparentid: "clone-box-and-" + parentid,
            branchname: this.slcand[x][0].name,
          });
        }
        if (chkcondor) {
          this.updateValue.push({
            condition_id: this.slcand[x][1].conditions.condition_id,
            control_id: this.slcand[x][1].conditions.control_id,
            condition_type: this.slcand[x][1].conditions.condition_type,
            control: this.slcand[x][1].conditions.control,
            subcontrol: this.arrsubcontrols,
            state: this.slcand[x][1].conditions.state,
            value: this.slcand[x][1].conditions.value,
            dataid: "clone-box-mor-" + parentid,
            dataparentid: "clone-box-mor-" + parentid,
            branchname: this.slcand[x][0].name,
          });
        }
      }
    }
  }

  getControls() {
    this.api.getControls(this.formid).subscribe((res: any) => {
      for (let i = 0; i < res.controls.length; i++) {
        if (
          res.controls[i].controlType != "header" &&
          res.controls[i].controlType != "button" &&
          res.controls[i].controlType != "sectionbreak" &&
          res.controls[i].controlType != "pagenavigator" &&
          res.controls[i].controlType != "social" &&
          res.controls[i].controlType != "divider" &&
          res.controls[i].controlType != "formheader" &&
          res.controls[i].controlType != "footer" &&
          res.controls[i].controlType != "image" &&
          res.controls[i].controlType != "typoparagraph" &&
          res.controls[i].controlType != "twocolumntext" &&
          res.controls[i].controlType != "imagetext" &&
          res.controls[i].controlType != "jumbotron" &&
          res.controls[i].controlType != "features" &&
          res.controls[i].controlType != "servicelist" &&
          res.controls[i].controlType != "pricetable" &&
          res.controls[i].controlType != "list" &&
          res.controls[i].controlType != "groups" &&
          res.controls[i].controlType != "tablelayout" &&
          res.controls[i].controlType != "groupcontrol" &&
          res.controls[i].controlType != "rankingchoice" &&
          res.controls[i].controlType != "age" &&
          res.controls[i].controlType != "signature" &&
          res.controls[i].controlType != "barcode" &&
          res.controls[i].controlType != "expressionbox" &&
          res.controls[i].controlType != "recaptcha" &&
          res.controls[i].controlType != "fingerprint" &&
          res.controls[i].controlType != "Takephoto" &&
          res.controls[i].controlType != "geolocation" &&
          res.controls[i].controlType != "locationcoordinates" &&
          res.controls[i].controlType != "video" &&
          res.controls[i].controlType != "videoRecorder" &&
          res.controls[i].controlType != "fileupload" &&
          res.controls[i].controlType != "imagecropper" &&
          res.controls[i].controlType != "pagebreak"
        ) {
          this.formControls.push(res.controls[i]);
          if (i == 0) {
            this.setApplicableStates(res.controls[i].controlType);
          }
        }
      }
    });
  }

  bindTypesOnUpdate() {
    this.api.getControlMappingsForRules().subscribe((res: any) => {
      this.bindTypeResponse(res.states);
    });
  }

  bindTypeResponse(resp) {
    let response = JSON.parse(JSON.stringify(resp));
    for (let x = 0; x < response.length; x++) {
      this.applicablestate.push({
        _id: response[x]._id,
        controlType: response[x].controlType,
        states: response[x].states,
      });
    }
  }

  ngAfterViewInit(): void {
    var self = this;
    this.generateFields(self, this.cloneCount, this.childcount);
    this.invokescript(self);

    this.applicablestate = [];
    this.removeTags();
  }

  setConditionOnControlState(state, type) {
    // 1 - filled,
    // 2 - empty
    // 3 - valequalsto
    switch (state.trim()) {
      case "is equal to":
      case "is not equal to":
      case "contains":
      case "does not contain":
      case "begins with":
      case "ends with":
        switch (type) {
          case "textbox":
          case "paragraph":
          case "email":
          case "phone":
          case "name":
          case "address":
          case "website":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.text = true;
            break;
          case "numberbox":
          case "price":
          case "sliderquestiontype":
          case "phone":
          case "website":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.number = true;
            break;
          case "date":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.date = true;
            break;
          case "time":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.time = true;
            break;
        }
        break;
      case "is greater than":
      case "is less than":
        switch (type) {
          case "numberbox":
          case "price":
          case "sliderquestiontype":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.number = true;
            break;
          case "date":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.date = true;
            break;
          case "time":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.time = true;
            break;
        }
        break;
      case "is selected":
      case "is not selected":
        switch (type) {
          case "checkboxes":
          case "dropdown":
          case "singlechoice":
            Object.keys(this.conditionsValue).forEach(
              (k) => (this.conditionsValue[k] = false)
            );
            this.conditionsValue.dropdown = true;
            this.setPossibleValues(type, "");
            break;
        }
        break;
      default:
        Object.keys(this.conditionsValue).forEach(
          (k) => (this.conditionsValue[k] = false)
        );
    }
  }
  setPossibleValues(type, typeval) {
    if (typeval != "") {
      this.formControls.forEach((ctrl) => {
        if (ctrl.controlType === type) {
          switch (ctrl.controlType) {
            case "checkboxes":
            case "singlechoice":
            case "dropdown":
              if (ctrl.id == typeval) {
                this.possibleValues = [];
                ctrl.choices.forEach((element) => {
                  this.possibleValues.push({
                    value: element.option,
                    viewValue: element.option,
                  });
                });
              }

              break;
          }
        }
      });
    }
  }

  setSubControls(ctrlType) {
    this.hasSubControls = false;
    switch (ctrlType) {
      case "name":
        this.hasSubControls = true;
        this.setSubcontrolsVal(ctrlType);
        break;
      case "address":
        this.hasSubControls = true;
        this.setSubcontrolsVal(ctrlType);
        break;
    }
  }
  setSubcontrolsVal(type) {
    this.formControls.forEach((ctrl) => {
      if (ctrl.controlType === type) {
        switch (ctrl.controlType) {
          case "name":
            if (Number(ctrl.nameFormat) === 0) {
              this.subControls = [];
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            } else if (Number(ctrl.nameFormat) === 1) {
              this.subControls = [];
              this.subControls.push({
                value: "title",
                viewValue: "Title",
              });
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            } else if (Number(ctrl.nameFormat) === 2) {
              this.subControls = [];
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "middleName",
                viewValue: "Middle Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            } else if (Number(ctrl.nameFormat) === 3) {
              this.subControls = [];
              this.subControls.push({
                value: "title",
                viewValue: "Title",
              });
              this.subControls.push({
                value: "firstName",
                viewValue: "First Name",
              });
              this.subControls.push({
                value: "middleName",
                viewValue: "Middle Name",
              });
              this.subControls.push({
                value: "lastName",
                viewValue: "Last Name",
              });
            }
            break;
          case "address":
            this.subControls = [];
            this.subControls.push({
              value: "addressLine1",
              viewValue: "Address Line 1",
            });
            if (!ctrl.hideAddressLine2) {
              this.subControls.push({
                value: "addressLine2",
                viewValue: "Address Line 2",
              });
            }
            this.subControls.push({
              value: "district",
              viewValue: "District",
            });
            this.subControls.push({
              value: "state",
              viewValue: "State",
            });
            this.subControls.push({
              value: "zip",
              viewValue: "Zip / Pin code",
            });
            break;
        }
      }
    });
  }

  setApplicableStates(controlType: string) {
    this.api.getControlMappingsForRules().subscribe(
      (res: any) => {
        res.states.forEach((control, i) => {
          if (res.states[i].controlType == controlType) {
            for (let x = 0; x < res.states[i].states.length; x++) {
              this.applicableStates.push(res.states[i].states[x]);
            }
          }
        });
      },
      (error) => {}
    );
  }

  getcloneboxid(ctype, boxid) {
    return "clone-box-" + ctype + "-" + boxid;
  }

  getclonechild(boxid) {
    return "dci-" + boxid;
  }

  generateFields(self, cloneCount, childCount) {
    //Main AND condition
    $(document)
      .off("click", "#btn_cond_and")
      .on("click", "#btn_cond_and", function (e) {
        self.branchid += 1;
        cloneCount += 1;
        $(document).find(".MainAnd").removeClass("hide");
        var $firstrow = $(".MainAnd").find("tr:first").clone();
        var $lastRow = $("[class$=MainAnd] tr:last");
        var $newRow = $lastRow.clone();
        $firstrow.find(".spnbranch").text("Branch " + self.branchid + "");
        if (self.branchid > 1) {
          $firstrow.find(".btn_parent_del").removeClass("hide");
        }
        $newRow.find(":text").val("");
        $firstrow.attr("id", "rwand-" + cloneCount);
        $firstrow.attr("data-main-id", "clone-box-and-" + cloneCount);
        $newRow.attr("id", "clone-box-and-" + cloneCount);

        var parentid, childid;
        if ($("[class$=filerTable] tr:last").attr("id")) {
          parentid = $("[class$=filerTable] tr:last").attr("id");
          $(document)
            .find("#" + parentid)
            .after($firstrow);

          $(document)
            .find("#rwand-" + cloneCount)
            .after($newRow);
        } else {
          parentid = $("[class$=filerTable] tr:last").attr("data-parent-id");
          childid = $("[class$=filerTable] tr:last").attr("data-child-id");
          $(document)
            .find("[data-child-id='" + childid + "']")
            .after($firstrow);

          $(document)
            .find("#rwand-" + cloneCount)
            .after($newRow);
        }

        $(document).find(".MainAnd").addClass("hide");
      });

    /* Child AND Condition  */
    $(document)
      .off("click", ".btn_child_and")
      .on("click", ".btn_child_and", function (e) {
        var rowid, childid;
        var datachildid = false;
        if ($(this).closest("tr").attr("id")) {
          rowid = $(this).closest("tr").attr("id");
          datachildid = false;
        } else {
          rowid = $(this).closest("tr").attr("data-parent-id");
          childid = $(this).closest("tr").attr("data-child-id");
          datachildid = true;
        }

        $(document).find(".filterAndTable").removeClass("hide");
        var $lastRow = $("[class$=filterAndTable] tr:not():last");
        var $newRow = $lastRow.clone();
        $newRow.attr("data-parent-id", rowid);
        $newRow.attr("data-child-id", "dci-" + childCount++);
        $newRow.find(":text").val("");
        datachildid
          ? $(document)
              .find("[data-child-id='" + childid + "']")
              .after($newRow)
          : $(document)
              .find("#" + rowid)
              .after($newRow);
        $(document).find(".filterAndTable").addClass("hide");
      });

    //Delete on parent button
    $(document)
      .off("click", ".btn_parent_del")
      .on("click", ".btn_parent_del", function (e) {
        let colpcount = 0;
        self.branchid -= 1;
        var rowid = $(this).closest("tr").attr("data-main-id");
        if (self.branchid == 1) {
          $(this).addClass("hide");
          $(this).closest("tr").find("td:eq(2)").text("&nbsp;");
        } else {
          $(this).removeClass("hide");
        }
        $("table.filerTable tr").each(function (index) {
          if ($(this).attr("data-main-id")) {
            colpcount += 1;
            $(this)
              .find(".spnbranch")
              .text("Branch " + colpcount + "");
          }
        });
        if (colpcount > 1) {
          $('[data-parent-id="' + rowid + '"]').each(function () {
            $(this).closest("tr").remove();
          });
          $('[data-main-id="' + rowid + '"]').each(function () {
            $(this).closest("tr").remove();
          });
          $("#" + rowid).remove();
        }
        let coland = 0;
        $("table.filerTable tr").each(function (index) {
          if ($(this).attr("data-main-id")) {
            coland += 1;
            $(this)
              .find(".spnbranch")
              .text("Branch " + coland + "");
          }
        });
      });
    //click on delete condition
    $(document)
      .off("click", ".deleteButton")
      .on("click", ".deleteButton", function (e) {
        if ($(this).closest("tr").attr("data-parent-id")) {
          var rowid = $(this).closest("tr").attr("data-parent-id");
          let delcolidcount = 0,
            colpcount = 0;

          $('[data-parent-id="' + rowid + '"]').each(function () {
            delcolidcount += 1;
          });
          $("table.filerTable tr").each(function () {
            if ($(this).attr("id")) {
              colpcount += 1;
            }
          });
          if (delcolidcount == 0 && colpcount > 2) {
            $(this).closest("tr").prev("tr").remove();
          }
          $(this).closest("tr").remove();
        } else {
          var rowid = $(this).closest("tr").attr("id");

          if ($(this).closest("tr").attr("id")) {
            let delcolidcount = 0;
            $('[data-parent-id="' + rowid + '"]').each(function () {
              delcolidcount += 1;
            });
            let colpcount = 0;
            $("table.filerTable tr").each(function () {
              if ($(this).attr("id")) {
                colpcount += 1;
              }
            });
            if (delcolidcount == 0 && colpcount > 2) {
              $(this).closest("tr").prev("tr").remove();
            }
            if (colpcount > 2) {
              $(this).closest("tr").remove();
            }
          }
        }
      });

    //Child OR condition
    $(document)
      .off("click", ".btn_child_or")
      .on("click", ".btn_child_or", function (e) {
        var rowid, childid;
        var datachildid = false;
        if ($(this).closest("tr").attr("id")) {
          rowid = $(this).closest("tr").attr("id");
          datachildid = false;
        } else {
          rowid = $(this).closest("tr").attr("data-parent-id");
          childid = $(this).closest("tr").attr("data-child-id");
          datachildid = true;
        }

        $(document).find(".filterOrTable").removeClass("hide");
        var $lastRow = $("[class$=filterOrTable] tr:not():last");
        var $newRow = $lastRow.clone();
        $newRow.attr("data-parent-id", rowid);
        $newRow.attr("data-child-id", "dci-" + childCount++);
        $newRow.find(":text").val("");
        datachildid
          ? $(document)
              .find("[data-child-id='" + childid + "']")
              .after($newRow)
          : $(document)
              .find("#" + rowid)
              .after($newRow);

        $(document).find(".filterOrTable").addClass("hide");
      });
  }

  invokescript(_self) {
    $(document).on("change", ".slc-cnd-name", function (e) {
      let controlType = e.target.value;
      let selectedI = this.selectedIndex;
      let ctrlValue = this.options[selectedI].dataset.id;
      _self.api
        .getControlMappingsForRules(controlType)
        .subscribe((res: any) => {
          _self.bindslcand = "";
          _self.bindsubCtrl = "";
          let rowid = "0";
          _self.setSubControls(controlType);
          _self.setPossibleValues(controlType, ctrlValue);

          if (e.currentTarget.closest("tr").hasAttribute("id")) {
            rowid = e.currentTarget.closest("tr").getAttribute("id");
          } else {
            rowid = e.currentTarget.closest("tr").getAttribute("data-child-id");
          }

          for (let x = 0; x < _self.subControls.length; x++) {
            _self.bindsubCtrl +=
              "<option value=" +
              _self.subControls[x].value +
              ">" +
              _self.subControls[x].viewValue +
              "</option>";
          }
          for (let x = 0; x < res.states.length; x++) {
            if (controlType == res.states[x].controlType) {
              for (let y = 0; y < res.states[x].states.length; y++) {
                _self.bindslcand +=
                  "<option>" + res.states[x].states[y] + "</option>";
              }
            }
          }

          if ($(this).closest("tr").attr("id")) {
            if (_self.hasSubControls)
              $(this).closest("tr").find("td:eq(2)").removeClass("hide");
            else $(this).closest("tr").find("td:eq(2)").addClass("hide");
          } else {
            if (_self.hasSubControls)
              $(this).closest("tr").find("td:eq(3)").removeClass("hide");
            else $(this).closest("tr").find("td:eq(3)").addClass("hide");
          }

          var itable = document.getElementById(
            "filerTable"
          ) as HTMLTableElement;
          for (let i in itable.rows) {
            let irow = itable.rows[i];
            for (let j in irow.cells) {
              if (j == "0") {
                if (irow.hasAttribute("id")) {
                  let tablerowid = irow.getAttribute("id");
                  if (rowid == tablerowid) {
                    $(irow)
                      .find("td:eq(3) select.slc-cnd-type")
                      .html(_self.bindslcand);
                    $(irow)
                      .find("td:eq(2) select.slc_subcontrols")
                      .html(_self.bindsubCtrl);
                    $(irow)
                      .find("td:eq(3) select.slc-cnd-type")
                      .trigger("change");
                  }
                }
                if (irow.hasAttribute("data-child-id")) {
                  let tablerowid = irow.getAttribute("data-child-id");
                  if (rowid == tablerowid) {
                    $(irow)
                      .find("td:eq(4) select.slc-cnd-type")
                      .html(_self.bindslcand);
                    $(irow)
                      .find("td:eq(3) select.slc_subcontrols")
                      .html(_self.bindsubCtrl);
                    $(irow)
                      .find("td:eq(4) select.slc-cnd-type")
                      .trigger("change");
                  }
                }
              }
            }
          }
        });
    });

    $(document).on("change", ".slc-cnd-type", function (e) {
      var state;
      var ctrltype = e.target.value;
      if (e.currentTarget.closest("tr").hasAttribute("id")) {
        state = $(this)
          .closest("tr")
          .find("td:eq(1) select.form-select>option:checked")
          .val();
      } else {
        state = $(this)
          .closest("tr")
          .find("td:eq(2) select.form-select>option:checked")
          .val();
      }
      _self.setConditionOnControlState(ctrltype, state);
      var txtindex = $(this).closest("tr").find("td").length <= 10 ? 4 : 5;
      var numindex = $(this).closest("tr").find("td").length <= 10 ? 5 : 6;
      var dropdownindex = $(this).closest("tr").find("td").length <= 10 ? 6 : 7;
      var dateindex = $(this).closest("tr").find("td").length <= 10 ? 7 : 8;
      var timeindex = $(this).closest("tr").find("td").length <= 10 ? 8 : 9;

      switch (_self.conditionsValue.text) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + txtindex + ")")
            .removeClass("hide");
          break;
        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + txtindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.number) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + numindex + ")")
            .removeClass("hide");
          break;

        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + numindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.dropdown) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + dropdownindex + ")")
            .removeClass("hide");
          var slcdropdown = "";
          for (let x = 0; x < _self.possibleValues.length; x++) {
            slcdropdown +=
              "<option>" + _self.possibleValues[x].viewValue + "</option>";
          }
          $(this)
            .closest("tr")
            .find("td:eq(" + dropdownindex + ") select")
            .html(slcdropdown);
          break;

        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + dropdownindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.date) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + dateindex + ")")
            .removeClass("hide");
          activateDatepicker();

          break;
        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + dateindex + ")")
            .addClass("hide");
          break;
      }
      switch (_self.conditionsValue.time) {
        case true:
          $(this)
            .closest("tr")
            .find("td:eq(" + timeindex + ")")
            .removeClass("hide");
          activateDatepicker();

          break;
        case false:
          $(this)
            .closest("tr")
            .find("td:eq(" + timeindex + ")")
            .addClass("hide");
          break;
      }
    });

    $(document).on("click", ".slcoptions", function (e) {
      let ctrltype, ctrlVal;
      var stroption = "";
      if (e.currentTarget.closest("tr").hasAttribute("id")) {
        ctrltype = $(this)
          .closest("tr")
          .find("td:eq(1) select.form-select>option:checked")
          .val();
        ctrlVal = $(this)
          .closest("tr")
          .find("td:eq(1) select.form-select>option:checked")
          .attr("data-id");
      } else {
        ctrltype = $(this)
          .closest("tr")
          .find("td:eq(2) select.form-select>option:checked")
          .val();
        ctrlVal = $(this)
          .closest("tr")
          .find("td:eq(2) select.form-select>option:checked")
          .attr("data-id");
      }
      _self.setPossibleValues(ctrltype, ctrlVal);
      for (let x = 0; x < _self.possibleValues.length; x++) {
        if ($(this).val() == _self.possibleValues[x].viewValue) {
          stroption +=
            "<option selected>" +
            _self.possibleValues[x].viewValue +
            "</option>";
        } else {
          stroption +=
            "<option>" + _self.possibleValues[x].viewValue + "</option>";
        }
      }
      $(this).html(stroption);
    });
  }

  removeTags() {
    $(document).on("click", ".datepickerbutton", function (e) {
      $("table.filerTable tr").each(function () {
        $(this)
          .find("td")
          .each(function () {
            $(this).removeAttr("ng-reflect-ng-class");
          });
      });
      activateDatepicker();
    });
  }

  saveform(formstate) {
    this.andArray = [];
    let validform = true;
    validform = this.approvalConditions();
    if (this.form.value.workflowname == "") {
      validform = false;
    }

    if (validform) {
      this.data = {
        beginid: this.beginid,
        workflowid: this.form.value.workflowid,
        formid: this.form.value.formid,
        nodeid: this.form.value.nodeid,
        nodetype: this.nodetype,
        formname: this.form.value.workflowname,
        stage: this.form.value.workflowstage,
        slcand: this.andArray,
        status: true,
        userid: this.api.getUserId(),
      };
      this.api.cbsave = this.andArray;

      if (this.beginid === 0 && formstate == "save") {
        this.api.saveCondBranch(this.data).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success(
                "Conditional Branch saved successfully",
                "Success"
              );
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Conditional Branch failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      } else {
        this.api.updateCondBranch(this.data, this.data.beginid).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success(
                "Conditional Branch updated successfully",
                "Success"
              );
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Conditional Branch failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      }

      this.dialogRef.close(this.form.value);
    } else {
      this.toastr.error("Please fill all mandatory fields!", "Error!");
    }
  }
  approvalConditions() {
    let chkcondition = true;
    let branchid = 0;
    var table = document.getElementById("filerTable") as HTMLTableElement;
    for (let i in table.rows) {
      let row = table.rows[i];
      for (let j in row.cells) {
        let col = row.cells[j];
        if (j == "0") {
          if (row.hasAttribute("id")) {
            var colcount = $(row).find("td").length;
            if (row.classList.contains("trcondand")) {
              branchid += 1;
              if (colcount == 10) {
                let vcol = this.getValueColumn(
                  colcount,
                  $(row)
                    .find("td:eq(1) select.form-select>option:checked")
                    .val()
                );
                let chksubctrl = $(row).find("td:eq(2)").hasClass("hide");
                if (chksubctrl) {
                  this.andArray.push([
                    { name: "Branch " + branchid + "" },
                    {
                      conditions: {
                        condition_id: parseInt(i) + 1,
                        control_id: $(row)
                          .find("td:eq(1) select.form-select>option:checked")
                          .attr("data-id"),
                        control: $(row)
                          .find("td:eq(1) select.form-select>option:checked")
                          .val(),
                        controlname: $(row)
                          .find("td:eq(1) select.form-select>option:checked")
                          .text(),
                        condition_type: $(row)
                          .find("td:eq(3) select.form-select>option:checked")
                          .text(),
                        state: "and",
                        value:
                          $(row).find("td:eq(" + vcol + ") input").length > 0
                            ? $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") input[name='txtcondvalue']"
                                )
                                .val()
                            : $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") select.form-select>option:checked"
                                )
                                .val(),
                      },
                    },
                  ]);
                } else {
                  this.andArray.push([
                    { name: "Branch " + branchid + "" },
                    {
                      conditions: {
                        condition_id: parseInt(i) + 1,
                        control_id: $(row)
                          .find("td:eq(1) select.form-select>option:checked")
                          .attr("data-id"),
                        control: $(row)
                          .find("td:eq(1) select.form-select>option:checked")
                          .val(),
                        controlname: $(row)
                          .find("td:eq(1) select.form-select>option:checked")
                          .text(),
                        subcontrol: [
                          {
                            value: $(row)
                              .find(
                                "td:eq(2) select.form-select>option:checked"
                              )
                              .val(),
                            text: $(row)
                              .find(
                                "td:eq(2) select.form-select>option:checked"
                              )
                              .text(),
                          },
                        ],
                        condition_type: $(row)
                          .find("td:eq(3) select.form-select>option:checked")
                          .text(),
                        state: "and",
                        value:
                          $(row).find("td:eq(" + vcol + ") input").length > 0
                            ? $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") input[name='txtcondvalue']"
                                )
                                .val()
                            : $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") select.form-select>option:checked"
                                )
                                .val(),
                      },
                    },
                  ]);
                }
                if (
                  $(row)
                    .find("td:eq(3) select.form-select>option:checked")
                    .text() == ""
                ) {
                  chkcondition = false;
                }
              }
            }
          }
          if (row.hasAttribute("data-parent-id")) {
            var colcount = $(row).find("td").length;
            if (row.classList.contains("trcondand")) {
              if (colcount == 11) {
                let vcol = this.getValueColumn(
                  colcount,
                  $(row)
                    .find("td:eq(2) select.form-select>option:checked")
                    .val()
                );
                let chksubctrl = $(row).find("td:eq(3)").hasClass("hide");
                if (chksubctrl) {
                  this.andArray.push([
                    { name: "Branch " + branchid + "" },
                    {
                      conditions: {
                        condition_id: parseInt(i) + 1,
                        control_id: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .attr("data-id"),
                        control: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .val(),
                        controlname: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .text(),
                        condition_type: $(row)
                          .find("td:eq(4) select.form-select>option:checked")
                          .text(),
                        state: "childand",
                        value:
                          $(row).find("td:eq(" + vcol + ") input").length > 0
                            ? $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") input[name='txtcondvalue']"
                                )
                                .val()
                            : $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") select.form-select>option:checked"
                                )
                                .val(),
                      },
                    },
                  ]);
                } else {
                  this.andArray.push([
                    { name: "Branch " + branchid + "" },
                    {
                      conditions: {
                        condition_id: parseInt(i) + 1,
                        control_id: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .attr("data-id"),
                        control: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .val(),
                        controlname: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .text(),
                        subcontrol: [
                          {
                            value: $(row)
                              .find(
                                "td:eq(3) select.form-select>option:checked"
                              )
                              .val(),
                            text: $(row)
                              .find(
                                "td:eq(3) select.form-select>option:checked"
                              )
                              .text(),
                          },
                        ],
                        condition_type: $(row)
                          .find("td:eq(4) select.form-select>option:checked")
                          .text(),
                        state: "childand",
                        value:
                          $(row).find("td:eq(" + vcol + ") input").length > 0
                            ? $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") input[name='txtcondvalue']"
                                )
                                .val()
                            : $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") select.form-select>option:checked"
                                )
                                .val(),
                      },
                    },
                  ]);
                }

                if (
                  $(row)
                    .find("td:eq(4) select.form-select>option:checked")
                    .text() == ""
                ) {
                  chkcondition = false;
                }
              }
            }
            if (row.classList.contains("trcondor")) {
              if (colcount == 11) {
                let vcol = this.getValueColumn(
                  colcount,
                  $(row)
                    .find("td:eq(2) select.form-select>option:checked")
                    .val()
                );
                let chksubctrl = $(row).find("td:eq(3)").hasClass("hide");
                if (chksubctrl) {
                  this.andArray.push([
                    { name: "Branch " + branchid + "" },
                    {
                      conditions: {
                        condition_id: parseInt(i) + 1,
                        control_id: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .attr("data-id"),
                        control: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .val(),
                        controlname: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .text(),
                        condition_type: $(row)
                          .find("td:eq(4) select.form-select>option:checked")
                          .text(),
                        state: "childor",
                        value:
                          $(row).find("td:eq(" + vcol + ") input").length > 0
                            ? $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") input[name='txtcondvalue']"
                                )
                                .val()
                            : $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") select.form-select>option:checked"
                                )
                                .val(),
                      },
                    },
                  ]);
                } else {
                  this.andArray.push([
                    { name: "Branch " + branchid + "" },
                    {
                      conditions: {
                        condition_id: parseInt(i) + 1,
                        control_id: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .attr("data-id"),
                        control: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .val(),
                        controlname: $(row)
                          .find("td:eq(2) select.form-select>option:checked")
                          .text(),
                        subcontrol: [
                          {
                            value: $(row)
                              .find(
                                "td:eq(3) select.form-select>option:checked"
                              )
                              .val(),
                            text: $(row)
                              .find(
                                "td:eq(3) select.form-select>option:checked"
                              )
                              .text(),
                          },
                        ],
                        condition_type: $(row)
                          .find("td:eq(4) select.form-select>option:checked")
                          .text(),
                        state: "childor",
                        value:
                          $(row).find("td:eq(" + vcol + ") input").length > 0
                            ? $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") input[name='txtcondvalue']"
                                )
                                .val()
                            : $(row)
                                .find(
                                  "td:eq(" +
                                    vcol +
                                    ") select.form-select>option:checked"
                                )
                                .val(),
                      },
                    },
                  ]);
                }

                if (
                  $(row)
                    .find("td:eq(4) select.form-select>option:checked")
                    .text() == ""
                ) {
                  chkcondition = false;
                }
              }
            }
          }
        }
      }
    }

    return chkcondition;
  }

  getColumnFive(ctrltype, condition_type) {
    let cssclass = "hide";
    if (
      ctrltype == "textbox" ||
      ctrltype == "paragraph" ||
      ctrltype == "name" ||
      ctrltype == "phone" ||
      ctrltype == "address" ||
      ctrltype == "website" ||
      ctrltype == "email" ||
      ctrltype == "matrixchoice"
    ) {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnSix(ctrltype, condition_type) {
    let cssclass = "hide";
    if (
      ctrltype == "numberbox" ||
      ctrltype == "sliderquestiontype" ||
      ctrltype == "price"
    ) {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnSeven(ctrltype, condition_type) {
    let cssclass = "hide";
    if (
      ctrltype == "checkboxes" ||
      ctrltype == "singlechoice" ||
      ctrltype == "dropdown"
    ) {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnEight(ctrltype, condition_type) {
    let cssclass = "hide";
    if (ctrltype == "date") {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }
  getColumnNine(ctrltype, condition_type) {
    let cssclass = "hide";
    if (ctrltype == "time") {
      cssclass = this.getConditionsValueOnUpdate(ctrltype, condition_type);
    }
    return cssclass;
  }

  getConditionsValueOnUpdate(ctrltype, condition_type) {
    let strclass = "";
    this.setConditionOnControlState(condition_type, ctrltype);
    if (
      ctrltype == "textbox" ||
      ctrltype == "paragraph" ||
      ctrltype == "name" ||
      ctrltype == "phone" ||
      ctrltype == "address" ||
      ctrltype == "website" ||
      ctrltype == "email" ||
      ctrltype == "matrixchoice"
    ) {
      strclass = this.conditionsValue.text ? "" : "hide";
    }
    if (
      ctrltype == "numberbox" ||
      ctrltype == "sliderquestiontype" ||
      ctrltype == "price"
    ) {
      strclass = this.conditionsValue.number ? "" : "hide";
    }
    if (
      ctrltype == "checkboxes" ||
      ctrltype == "singlechoice" ||
      ctrltype == "dropdown"
    ) {
      strclass = this.conditionsValue.dropdown ? "" : "hide";
    }
    if (ctrltype == "date") {
      strclass = this.conditionsValue.date ? "" : "hide";
    }
    if (ctrltype == "time") {
      strclass = this.conditionsValue.time ? "" : "hide";
    }

    return strclass;
  }

  getValueColumn(colcount, ctrltype) {
    let colvalue = 0;
    if (
      ctrltype == "textbox" ||
      ctrltype == "paragraph" ||
      ctrltype == "name" ||
      ctrltype == "phone" ||
      ctrltype == "address" ||
      ctrltype == "website" ||
      ctrltype == "email" ||
      ctrltype == "matrixchoice"
    ) {
      colvalue = colcount == 10 ? 4 : 5;
    }
    if (
      ctrltype == "numberbox" ||
      ctrltype == "sliderquestiontype" ||
      ctrltype == "price"
    ) {
      colvalue = colcount == 10 ? 5 : 6;
    }
    if (
      ctrltype == "checkboxes" ||
      ctrltype == "singlechoice" ||
      ctrltype == "dropdown"
    ) {
      colvalue = colcount == 10 ? 6 : 7;
    }
    if (ctrltype == "date") {
      colvalue = colcount == 10 ? 7 : 8;
    }
    if (ctrltype == "time") {
      colvalue = colcount == 10 ? 8 : 9;
    }
    return colvalue;
  }

  clearTable() {
    $("#filerTable tr").each(function () {
      $(this)
        .find("td")
        .each(function () {
          if ($(this).hasClass("hide")) {
            $(this).remove();
          }
        });
    });
  }

  close() {
    this.dialogRef.close();
  }
}
