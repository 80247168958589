import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import $ from "jquery";
import Drawflow from "drawflow";
import { LayoutService, LayoutInitService } from "../../../_qform/core";
import QFLayoutContent from "../../../../assets/js/layout/base/content";
import QFLayoutHeader from "../../../../assets/js/layout/base/header";
import QFLayoutHeaderMenu from "../../../../assets/js/layout/base/header-menu";
import QFLayoutBrand from "../../../../assets/js/layout/base/brand";
import QFLayoutAside from "../../../../assets/js/layout/base/aside";
import QFLayoutAsideMenu from "../../../../assets/js/layout/base/aside-menu";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { QFUtil } from "../../../../assets/js/components/util";
import QFLayoutAsideToggle from "../../../../assets/js/layout/base/aside-toggle";
import KTLayoutStretchedCard from "../../../../assets/js/layout/base/stretched-card";
import QFLayoutHeaderTopbar from "../../../../assets/js/layout/base/header-topbar";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from "@angular/router";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { BeginComponent } from "src/app/modals/begin/begin.component";
import { ApprovalComponent } from "src/app/modals/approval/approval.component";
import { AssignComponent } from "src/app/modals/assign/assign.component";
import { SendemailComponent } from "src/app/modals/sendemail/sendemail.component";
import { ApprovalreportComponent } from "src/app/modals/approvalreport/approvalreport.component";
import { ConditionalbranchComponent } from "src/app/modals/conditionalbranch/conditionalbranch.component";
import { IfelseComponent } from "src/app/modals/ifelse/ifelse.component";
import { MergebranchesComponent } from "src/app/modals/mergebranches/mergebranches.component";
import { SplitComponent } from "src/app/modals/split/split.component";
import { RunscriptComponent } from "src/app/modals/runscript/runscript.component";
import { EndflowComponent } from "src/app/modals/endflow/endflow.component";
import { IntegrationhubComponent } from "src/app/modals/integrationhub/integrationhub.component";

@Component({
  selector: "app-workflow",
  templateUrl: "./workflow.component.html",
  styleUrls: ["./workflow.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WorkflowComponent implements OnInit {
  selfLayout = "default";
  asideCSSClasses: string;
  headerCSSClasses: string;
  headerMenuSelfDisplay: boolean;
  brandSkin: string;
  asideMenuHTMLAttributes: any = {};
  asideSelfMinimizeToggle = false;
  asideSelfDisplay: boolean;
  contentClasses = "";
  contentContainerClasses = "";
  headerContainerCSSClasses: string;
  headerMenuHTMLAttributes: any = {};
  headerMenuCSSClasses: string;
  headerMobileClasses = "";
  brandClasses: string;
  asideheaderid: any;
  headerLogo: string;
  disableAsideSelfDisplay: boolean;
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  asideMenuScroll = 1;
  ulCSSClasses: string;
  location: Location;
  ispublished: boolean = false;
  workflowid: any;
  entryid: any;
  formstate: any;
  mobile_item_selec: any;
  headerid: any;
  routerLoaderTimout: any;
  formname: any;
  qformname: any;
  lastUpdated: any;

  id: any;
  editor: any;
  mobile_last_move: any;
  qfdialog: any;
  dataToImport: any;
  formid: any;
  workdata: any;
  isUpdate = false;
  elementdivid: any;
  ruleid: any;
  ruledetails: any;
  isUnsaved = false;
  dialogRef: any;
  slcand: any = [];
  state: any;
  isSaving: boolean = false;
  nodex: any;
  nodey: any;
  arrstages: any = [];
  userId: any;
  access_token: any;
  isConnected: boolean = false;
  objAccounts = new Subject();
  workflowStages = [];

  @ViewChild("ktAside", { static: true }) ktAside: ElementRef;
  @ViewChild("ktHeaderMobile", { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild("ktHeader", { static: true }) ktHeader: ElementRef;
  @ViewChild("ktHeaderMenu", { static: true }) ktHeaderMenu: ElementRef;
  loader$: Observable<number>;

  private loaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  private unsubscribe: Subscription[] = [];

  constructor(
    private initService: LayoutInitService,
    private layout: LayoutService,
    private api: WorkFlowService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public datepipe: DatePipe
  ) {
    this.initService.init();
    this.qfdialog = this.dialog;
    this.api.setUnsaved("false");
    this.loader$ = this.loaderSubject;
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loaderSubject.next(10);
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loaderSubject.next(65);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loaderSubject.next(90);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loaderSubject.next(100);
        if (this.routerLoaderTimout) {
          clearTimeout(this.routerLoaderTimout);
        }
        this.routerLoaderTimout = setTimeout(() => {
          this.loaderSubject.next(0);
        }, 300);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.headerid = this.route.snapshot.paramMap.get("formid");
    this.access_token = this.api.getiHubToken();
  }

  ngOnInit(): void {
    // load view settings
    this.selfLayout = this.layout.getProp("self.layout");
    this.headerMobileClasses = this.layout.getStringCSSClasses("header_mobile");
    this.headerMenuCSSClasses = this.layout.getStringCSSClasses("header_menu");
    this.asideSelfDisplay = this.layout.getProp("aside.self.display");
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
    this.asideSelfMinimizeToggle = this.layout.getProp(
      "aside.self.minimize.toggle"
    );
    this.contentContainerClasses =
      this.layout.getStringCSSClasses("content_container");
    this.asideCSSClasses = this.layout.getStringCSSClasses("aside");
    this.layout.getHTMLAttributes("header_mobile");
    this.headerCSSClasses = this.layout.getStringCSSClasses("header");
    this.disableAsideSelfDisplay =
      this.layout.getProp("aside.self.display") === false;
    this.brandSkin = this.layout.getProp("brand.self.theme");
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
    this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown")
      ? "1"
      : "0";
    this.brandClasses = this.layout.getProp("brand");
    this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
    this.headerMenuHTMLAttributes =
      this.layout.getHTMLAttributes("header_menu");

    const URLpath: string[] = this.api.getURLCleaned();

    if (URLpath.length == 6) {
      this.asideheaderid = this.route.snapshot.paramMap.get("formid");
      this.workflowid = this.route.snapshot.paramMap.get("workflowid");
      this.formstate = this.route.snapshot.paramMap.get("formstate");
      this.entryid = this.route.snapshot.paramMap.get("entryid");
      this.formid = this.route.snapshot.paramMap.get("formid");
    } else {
      this.asideheaderid = this.route.snapshot.paramMap.get("formid");
      this.workflowid = this.route.snapshot.paramMap.get("workflowid");
      this.formstate = this.route.snapshot.paramMap.get("formstate");
      this.formid = this.route.snapshot.paramMap.get("formid");
    }

    this.ispublished = this.formstate === "publish" ? true : false;
    this.qformname = localStorage.getItem("formname");
    this.userId = JSON.parse(atob(this.access_token.split(".")[1]));
    this.api.userEmail = this.userId.email;
    this.userId = this.userId.id;
    this.getForm(this.formid);

    if (this.formid === "0" || this.formid == null) {
      this.lastUpdated = Date.now();
    } else {
      this.formstate == "publish"
        ? this.getlastUpdatedPublish(this.workflowid)
        : "";
    }

    if (this.workflowid != null) {
      this.isUpdate = true;
      switch (this.formstate) {
        case "publish":
          URLpath.length == 6
            ? this.getWorkflowStages(this.formid, this.entryid)
            : this.generatePublishWorkFlowOnId(this.workflowid);
          break;
        case "checkout":
          this.generateWorkFlowOnId(this.workflowid);
          break;
        default:
          break;
      }
      this.api.setFormId(this.formid);
    } else {
      this.router.navigateByUrl("dashboardlist/" + this.formid);
    }
  }

  getForm(formId) {
    let accountId = 0;
    this.api.getForm(formId).subscribe(
      (res: any) => {
        if (res.form[0].isIntegrationHub) {
          accountId = res.form[0].hubAccountId;
          this.api.sfObjectId = accountId;
          this.getObjects(formId, this.userId, accountId);
          this.getIntegrationHubConfigurations();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getObjects(formId, userId, accountId) {
    this.api.getObjects(formId, userId, accountId).subscribe(
      (objects) => {
        this.objAccounts.next(objects["integration"]);
      },
      (err) => {
        this.toastr.error(err.message, "Error");
      }
    );
  }

  getIntegrationHubConfigurations() {
    this.api.iHubConfigList = [];
    this.objAccounts.subscribe((res: any) => {
      if (res.length > 0) {
        this.api
          .IntegrationHubConfiguration(this.api.getiHubToken())
          .subscribe((list) => {
            let data = list["configuration"];
            data.forEach((item) => {
              this.api.iHubConfigList.push({ item });
              this.getIntegrationHubUserAccounts(
                item,
                this.userId,
                this.access_token
              );
            });
          });
      }
    });
  }

  getIntegrationHubUserAccounts(item, userId, access_token) {
    const objUser = {
      userId: userId,
      accountName: item.hubName,
    };
    this.api.getHubAccounts(objUser, access_token).subscribe((data) => {
      if (data.status == "success") {
        this.bindHubUserAccounts(data.account, access_token);
      }
    });
  }
  bindHubUserAccounts(accounts, access_token) {
    this.api.objHubData = [];
    accounts.forEach((element) => {
      switch (element.accountName.toLowerCase()) {
        case "salesforce":
          if (element._id == this.api.sfObjectId) {
            this.api
              .getHubColumns(
                this.formid,
                element.userId,
                access_token,
                element.accountName,
                element._id
              )
              .subscribe((data) => {
                let hubData = data["integration"];
                hubData.forEach((element, index) => {
                  this.isConnected = true;
                  this.api.objHubData.push(element);
                });
              });
          }

          break;
      }
    });
  }

  getWorkflowStages(formid, entryid) {
    const arrelement = {};
    this.api.generatePublishWorkflowStages(formid, entryid).subscribe(
      (data: any) => {
        data.rows.forEach((mrows, mi) => {
          mrows.forEach((element) => {
            if (element.label == "Stage") {
              element.value.forEach((stgVal) => {
                this.arrstages.push({
                  nodeid: stgVal.nodeId,
                  rulename: stgVal.ruleName,
                  stage: stgVal.stage,
                  type: stgVal.type,
                });
              });
            }
          });
        });
        this.generatePublishWorkFlowOnId(this.workflowid);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private getLogo() {
    return "assets/media/logos/logo.png";
  }

  getlastUpdatedPublish(formId) {
    this.api.generatePublishWorkflowData(formId).subscribe(
      (data: any) => {
        if (
          data.workflow_data[0] === null ||
          data.workflow_data[0] === undefined
        ) {
          this.lastUpdated = "";
        } else {
          this.lastUpdated = data.workflow_data[0].updated_at;
          this.formname = data.workflow_data[0].form_name;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  generatePublishWorkFlowOnId(workflowid) {
    this.api.generatePublishWorkflowData(workflowid).subscribe(
      (res: any) => {
        if (
          res.workflow_data[0] === null ||
          res.workflow_data[0] === undefined
        ) {
          this.router.navigateByUrl("dashboardlist/" + this.formid);
        } else {
          this.workdata = res.workflow_data[0].workflowdata;
          this.workflowStages = res.workflow_data[0].stage;
          this.ispublished == true
            ? (this.changeMode("lock"),
              (QFUtil.getById("btnlock").style.display = "none"))
            : "";
          this.getData(workflowid, this.workdata);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  generateWorkFlowOnId(workflowid) {
    this.api.generateWorkflowData(workflowid).subscribe(
      (res: any) => {
        if (
          res.workflow_data[0] === null ||
          res.workflow_data[0] === undefined
        ) {
          this.lastUpdated = "";
          this.router.navigateByUrl("dashboardlist/" + this.formid);
        } else {
          this.workdata = res.workflow_data[0].workflowdata;
          this.lastUpdated = res.workflow_data[0].updated_at;
          this.formname = res.workflow_data[0].form_name;
          this.workflowStages = res.workflow_data[0].stage;
          this.getData(workflowid, this.workdata);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  populateWorkflowDataOnSave(workflowid) {
    this.api.generateWorkflowData(workflowid).subscribe(
      (res: any) => {
        if (
          res.workflow_data[0] !== null ||
          res.workflow_data[0] !== undefined
        ) {
          this.workdata = res.workflow_data[0].workflowdata;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getData(workflowid, data) {
    /* This code is for rectangular lines  */
    // this.editor.curvature = 0;
    // this.editor.force_first_input = true;
    // this.editor.reroute_curvature_start_end = 0;
    // this.editor.reroute_curvature = 100;
    // this.editor.reroute_width = 2;

    // this.editor.createCurvature = function (
    //   start_pos_x,
    //   start_pos_y,
    //   end_pos_x,
    //   end_pos_y,
    //   curvature_value
    // ) {
    //   var center_x = (end_pos_x - start_pos_x) / 1.2 + start_pos_x;
    //   return (
    //     " M " +
    //     start_pos_x +
    //     " " +
    //     start_pos_y +
    //     " L " +
    //     center_x +
    //     " " +
    //     start_pos_y +
    //     " L " +
    //     center_x +
    //     " " +
    //     end_pos_y +
    //     " L " +
    //     end_pos_x +
    //     " " +
    //     end_pos_y
    //   );
    // };

    this.dataToImport = JSON.parse(JSON.stringify(data));
    this.editor.reroute = true;
    this.editor.reroute_fix_curvature = true;
    this.editor.force_first_input = false;
    this.editor.start();

    this.editor.import(this.dataToImport);
    let _this = this;
    _this.changeNodeStage();
    _this.changeCbHeight(_this, null, this.dataToImport);

    this.editor.removeNodeId = function (id) {
      let nodename = _this.api.getDeleteNodeName().trim();
      if (nodename == "beginform" || nodename == "endflow") {
        _this.toastr.info("Begin and End Node cannot be deleted.");
      } else {
        this.removeConnectionNodeId(id);
        var moduleName = this.getModuleFromNodeId(id.slice(5));
        if (this.module === moduleName) {
          document.getElementById(id).remove();
        }
        delete this.drawflow.drawflow[moduleName].data[id.slice(5)];
        this.dispatch("nodeRemoved", id.slice(5));
      }
    };

    /* Delete Node */
    this.editor.on("nodeRemoved", function (id) {
      _this.api.setUnsaved("false");
      const nodename: string = _this.api.getDeleteNodeName().trim();
      let nodeid = "node-" + id;
      if ((1 == 1 && nodename != "beginform") || nodename != "endflow") {
        _this.api
          .deleteNode(workflowid, nodeid, _this.api.getDeleteNodeName().trim())
          .subscribe(
            (res: any) => {
              if (res && Number(res.responseCode) === 200) {
                if (res.exists) {
                  _this.api.setUnsaved("true");
                  _this.toastr.success(
                    "Node has been deleted successfully",
                    "Success"
                  );
                }
                _this.isUpdate = true;
                _this.saveworkflowdata();
              } else {
                _this.toastr.error("There is some problem! ", "Error!");
              }
            },
            (err) => {
              console.log(err);
            }
          );
      }
    });

    this.editor.on("nodeMoved", function (id) {
      _this.saveOnMove();
    });

    this.editor.on("connectionCreated", function (id) {
      _this.saveOnMove();
    });

    this.editor.on("connectionRemoved", function (id) {
      _this.saveOnMove();
    });
  }

  changeNodeStage() {
    if (this.arrstages.length > 0) {
      const cdata = this.workdata.drawflow.Home.data;
      Object.keys(cdata).map((key) => {
        var strnode = cdata[key].html;
        var strnodeval = $(strnode).find(".title").text();
        console.log(this.arrstages, strnodeval);
        var chip = "";

        if (key == "1" || key == "7") {
          chip =
            key == "1"
              ? '<div class="mini-stat-label endflow-status"><p class="mb-0">End</p></div>'
              : '<div class="mini-stat-label beginflow-status"><p class="mb-0">Start</p></div>';
          $(document)
            .find("#node-" + key)
            .append(chip);
        }

        this.arrstages.forEach((element) => {
          if (strnodeval == element.rulename) {
            if (element.type == "runscript" && element.stage == "Completed") {
              chip =
                '<div class="mini-stat-label executed"><p class="mb-0">Executed</p></div>';
            } else {
              chip =
                '<div class="mini-stat-label ' +
                element.stage.toLowerCase() +
                '"><p class="mb-0">' +
                element.stage +
                "</p></div>";
            }
            $(document)
              .find("#" + element.nodeid)
              .append(chip);
          } else {
            if (element.type == "sendemail") {
              if (element.stage == "Completed") {
                chip =
                  '<div class="mini-stat-label completed"><p class="mb-0">Email sent</p></div>';
              } else {
                chip =
                  '<div class="mini-stat-label inprogress"><p class="mb-0">Pending</p></div>';
              }
              $(document)
                .find("#" + element.nodeid)
                .append(chip);
            }
          }
        });
      });
    }
  }

  changeMode(option) {
    if (option == "lock") {
      this.editor.editor_mode = "fixed";
      QFUtil.getById(option).style.display = "none";
      QFUtil.getById("unlock").style.display = "block";
    } else {
      this.editor.editor_mode = "edit";
      QFUtil.getById("lock").style.display = "block";
      QFUtil.getById(option).style.display = "none";
    }
  }

  zoom_out() {
    this.editor.zoom_out();
  }
  zoom_reset() {
    this.editor.zoom_reset();
  }
  zoom_in() {
    this.editor.zoom_in();
  }
  clearModuleSelected() {
    this.editor.clearModuleSelected();
  }

  saveworkflowdata() {
    this.isSaving = true;
    if (this.isUpdate) {
      const data = {
        workflowdata: this.editor.export(),
      };
      this.api.updateWorkflow(data, this.workflowid).subscribe(
        (res: any) => {
          if (res && Number(res.responseCode) === 201) {
            this.isSaving = false;
            let date = new Date();
            this.lastUpdated = this.datepipe.transform(
              date,
              "MMM dd, yyyy hh:mm:ss a"
            );
          } else {
            this.toastr.error("Saving Workflow failed! ", "Error!");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  saveOnMove() {
    if (this.isUpdate) {
      this.isSaving = true;
      const data = {
        workflowdata: this.editor.export(),
      };
      this.api.updateWorkflow(data, this.workflowid).subscribe(
        (res: any) => {
          if (res && res.responseCode == 201) {
            this.isSaving = false;
            let date = new Date();
            this.lastUpdated = this.datepipe.transform(
              date,
              "MMM dd, yyyy hh:mm:ss a"
            );
          }
          //this.populateWorkflowDataOnSave(this.workflowid);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  positionMobile(ev) {
    this.mobile_last_move = ev;
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drop(ev) {
    this.isUnsaved = true;
    this.api.setUnsaved("true");
    if (ev.type === "touchend") {
      var parentqformsflow = document
        .elementFromPoint(
          this.mobile_last_move.touches[0].clientX,
          this.mobile_last_move.touches[0].clientY
        )
        .closest("#drawflow");
      if (parentqformsflow != null) {
        this.addNodeToqformsflow(
          this.mobile_item_selec,
          this.mobile_last_move.touches[0].clientX,
          this.mobile_last_move.touches[0].clientY
        );
      }
      this.mobile_item_selec = "";
    } else {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("node");
      this.addNodeToqformsflow(data, ev.clientX, ev.clientY);
    }
    this.isUpdate = true;
    this.saveworkflowdata();
    //this.populateWorkflowDataOnSave(this.workflowid);
  }
  drag(ev) {
    if (ev.type === "touchstart") {
      this.mobile_item_selec = ev.target
        .closest(".drag-qformsflow")
        .getAttribute("data-node");
    } else {
      ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
    }
  }

  addNodeToqformsflow(name, pos_x, pos_y) {
    if (this.editor.editor_mode === "fixed") {
      return false;
    }
    pos_x =
      pos_x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom));
    pos_y =
      pos_y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom));

    this.nodex = pos_x;
    this.nodey = pos_y;

    switch (name) {
      case "approval":
        var approval = `<div class="cardflow">
            <div class="title-box">
                <div class="tbold">
                <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" class="wfItem-icon">
                    <path d="M10.813 3.75h3.006L10.344.274v3.007c0 .259.21.469.469.469z" fill="#23283A"></path>
                    <path d="M3.313 16h9.374c.776 0 1.407-.63 1.407-1.406V4.688h-3.281c-.776 0-1.407-.631-1.407-1.407V0H3.312c-.775 0-1.406.63-1.406 1.406v13.188c0 .775.631 1.406 1.407 1.406zm2.812-8.469h4.688a.468.468 0 110 .938H6.125a.468.468 0 110-.938zm0 1.875h4.688a.468.468 0 110 .938H6.125a.469.469 0 110-.938zm0 1.875h4.688a.468.468 0 110 .938H6.125a.469.469 0 110-.938zM4.25 7.531a.469.469 0 110 .938.469.469 0 010-.938zm0 1.875a.469.469 0 110 .938.469.469 0 010-.938zm0 1.875a.469.469 0 110 .938.469.469 0 010-.938z" fill="#23283A"></path>
                </svg>Approval</div>
                 <div class="title text-truncate">Approve</div> </div>\n
                <table id="tblapproval" class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_approve">Approved</td></tr><tr><td class="ps-3 p-qsm-1 bg_rejected">Rejected</td></tr></table> </div>
                    `;
        this.editor.addNode(
          "approval",
          1,
          2,
          pos_x,
          pos_y,
          "approval",
          {},
          approval
        );
        break;
      case "assign":
        var assign = `<div class="cardflow">
                <div class="title-box">
                <div class="tbold">
                <svg width="16" height="16" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="approval_with_sign"><path d="M15.959.09l8.5 6.967a.38.38 0 01.058.548l-1.405 1.658a.403.403 0 01-.558.048h.001l-8.5-6.947a.38.38 0 01-.058-.548L15.412.139a.391.391 0 01.547-.048l-.001-.001zm3.103 14.969a.383.383 0 01-.31.273h-.003l-9.758 2.46 3.776-4.539a1.948 1.948 0 002.409-.475l.002-.003a1.947 1.947 0 00-.25-2.72l-.003-.003a1.945 1.945 0 00-2.739.25l-.003.004a1.938 1.938 0 00-.439 1.422v-.008c.038.396.19.75.422 1.039l-.003-.004-3.786 4.538.615-10.031a.38.38 0 01.212-.331l.003-.001 5.738-2.8 5.757 4.791-1.64 6.138z" fill="#23283A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.484h9.118v1.928h-7.19v20.311h14.38v-7.931h1.928v9.859H0V3.484z" fill="#23283A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M3.93 21.05H14.44v-1.777H3.93v1.777z" fill="#23283A"></path></svg> Assign</div>
                <div class="title text-truncate"></div> </div>\n
                <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Always</td></tr></table> </div>
                    `;
        this.editor.addNode("assign", 1, 1, pos_x, pos_y, "assign", {}, assign);
        break;
      case "sendemail":
        var sendemail = `<div class="cardflow">
                <div class="title-box">
                <div class="tbold">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 16" class="sendEmail"><path fill="#23283A" fill-rule="evenodd" d="M1.248.33c.04.031.07.05.095.075l8.293 7.724c.09.085.184.168.287.238a2.064 2.064 0 002.58-.213L20.807.416c.032-.03.06-.064.09-.097C20.492.082 20.06 0 19.604 0H2.56c-.456 0-.888.082-1.312.33m-.88.838c-.245.4-.364.807-.365 1.245C.002 6.04-.005 9.667.006 13.294c.004 1.433 1.063 2.47 2.515 2.471 5.706.006 11.412.002 17.118.004.459 0 .89-.096 1.28-.329.83-.494 1.238-1.23 1.241-2.181.004-1.023.001-2.046.001-3.069V2.554c0-.488-.09-.952-.366-1.38-.045.039-.082.068-.116.1-2.466 2.297-4.931 4.595-7.398 6.89-.416.388-.81.806-1.269 1.14-1.3.945-2.997.835-4.178-.259C6.26 6.662 3.7 4.268 1.134 1.88c-.25-.234-.502-.466-.766-.71"></path></svg> Notification</div>
                <div class="title text-truncate">Send Email</div> </div>\n
                <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Always</td></tr></table> </div>
                    `;
        this.editor.addNode(
          "sendemail",
          1,
          1,
          pos_x,
          pos_y,
          "sendemail",
          { name: "" },
          sendemail
        );
        break;
      case "approvalreport":
        var approvalreport = `<div class="cardflow">
                <div class="title-box">
                <div class="tbold">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 16" class="sendEmail"><path fill="#23283A" fill-rule="evenodd" d="M1.248.33c.04.031.07.05.095.075l8.293 7.724c.09.085.184.168.287.238a2.064 2.064 0 002.58-.213L20.807.416c.032-.03.06-.064.09-.097C20.492.082 20.06 0 19.604 0H2.56c-.456 0-.888.082-1.312.33m-.88.838c-.245.4-.364.807-.365 1.245C.002 6.04-.005 9.667.006 13.294c.004 1.433 1.063 2.47 2.515 2.471 5.706.006 11.412.002 17.118.004.459 0 .89-.096 1.28-.329.83-.494 1.238-1.23 1.241-2.181.004-1.023.001-2.046.001-3.069V2.554c0-.488-.09-.952-.366-1.38-.045.039-.082.068-.116.1-2.466 2.297-4.931 4.595-7.398 6.89-.416.388-.81.806-1.269 1.14-1.3.945-2.997.835-4.178-.259C6.26 6.662 3.7 4.268 1.134 1.88c-.25-.234-.502-.466-.766-.71"></path></svg> Notification</div>
                <div class="title text-truncate">Send Approval Report</div> </div>\n
                <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Always</td></tr></table></div>
                `;
        this.editor.addNode(
          "approvalreport",
          1,
          1,
          pos_x,
          pos_y,
          "approvalreport",
          {},
          approvalreport
        );
        break;
      case "conditionalbranch":
        var conditionalbranch = `<div class="cardflow">
                    <div class="title-box">
                    <div class="tbold">
                    <svg width="16" height="16" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="conditionalBranch"><path d="M5.748 7.415h2.229l1.332 2.007 2.274-3.052L9.51 3.586H5.748L5.782 0 0 5.814l5.738 5.701.01-4.1zm8.974 3.164L12.4 14.074l3.938 5.925h2.914c.775 0 1.404-.628 1.404-1.404v-1.828c0-.775-.628-1.404-1.404-1.404h-.965l-3.565-4.784zM18.218.001L24 5.816l-5.739 5.7-.008-4.1h-2.229L7.664 20H4.75a1.403 1.403 0 01-1.404-1.404v-1.828c0-.774.63-1.404 1.404-1.404h.964l8.778-11.778h3.761L18.218.001z" fill="#23283A"></path></svg> Conditional Branch</div>
                    <div class="title text-truncate">Conditions</div> </div>\n
                    <table id="tblcb" class="table table-row-bordered mb-0 tblcb"><tr><td class="ps-3 p-qsm-1 bg_cond">True</td></tr></table> </div>
                `;
        this.editor.addNode(
          "conditionalbranch",
          1,
          1,
          pos_x,
          pos_y,
          "conditionalbranch",
          {},
          conditionalbranch
        );
        break;
      case "ifelse":
        var ifelse = `<div class="cardflow">
                    <div class="title-box">
                    <div class="tbold">
                    <svg width="16" height="16" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="binaryCondition"><path d="M15.823 4.555L11.405 0v2.263H8.962a3.908 3.908 0 00-3.291 1.79L2.85 8.406a.236.236 0 00-.024.04H0v4.187h2.827c.007.013.014.027.023.04l2.82 4.354a3.908 3.908 0 003.292 1.79h2.443v2.495l4.418-4.555-4.418-4.556v2.428H9.108l-2.652-4.09 2.652-4.09h2.297v2.66l4.418-4.555zM20.26 8.28a3.74 3.74 0 100-7.48 3.74 3.74 0 000 7.48zm-1.304-4.375h.668v-.668a.636.636 0 111.271 0v.668h.669a.636.636 0 010 1.272h-.669v.67a.636.636 0 11-1.271 0v-.67h-.668a.635.635 0 110-1.272zM20.26 13.033a3.74 3.74 0 100 7.48 3.74 3.74 0 000-7.48zm1.937 2.726l-1.172 2.379a1.063 1.063 0 01-.83.56c-.038 0-.075.006-.112.006a1.062 1.062 0 01-.817-.384l-.859-1.035a.636.636 0 01.977-.815l.66.792 1.016-2.064a.636.636 0 111.14.561h-.003z" fill="#23283A"></path></svg> Condition</div>
                    <div class="title text-truncate">If Else</div> </div>\n
                    <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Yes</td></tr><tr><td class="ps-3 p-qsm-1 bg_cond">No</td></tr></table> </div>
                `;
        this.editor.addNode("ifelse", 1, 2, pos_x, pos_y, "ifelse", {}, ifelse);
        break;
      case "mergebranches":
        var mergebranches = `<div class="cardflow">
                    <div class="title-box">
                    <div class="tbold">
                    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" class="merge"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.314V0h4.065v6.133l5.41 2.615 5.41-2.613V0h4.066v6.311a3.797 3.797 0 01-1.737 3.193l-.107.069-5.6 2.71V19h2.296l-4.5 4.5-4.5-4.5h2.64v-6.711l-5.6-2.71-.106-.069A3.796 3.796 0 010 6.314z" fill="#23283A"></path></svg> Merge Branches</div>
                    <div class="title text-truncate">All</div> </div>\n
                    <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Always</td></tr></table> </div>
                `;
        this.editor.addNode(
          "mergebranches",
          2,
          1,
          pos_x,
          pos_y,
          "mergebranches",
          {},
          mergebranches
        );
        break;
      case "split":
        var split = `<div class="cardflow">
                    <div class="title-box">
                    <div class="tbold">
                    <svg width="16" height="16" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="split"><path d="M21.373 19.711V13.19a3.796 3.796 0 00-1.737-3.196l-.107-.069-5.599-2.71V0H9.865v7.22l-5.6 2.711L4.16 10a3.796 3.796 0 00-1.737 3.193v6.52H0l4.422 4.29 4.422-4.29H6.487V13.37l5.41-2.613 5.41 2.615v6.343h-2.582l4.423 4.289 4.422-4.29-2.197-.002z" fill="#23283A"></path></svg>Split</div>
                    <div class="title">Split</div> </div>\n
                    <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Always</td></tr></table> </div>
                `;
        this.editor.addNode("split", 1, 2, pos_x, pos_y, "split", {}, split);
        break;
      case "runscript":
        var runscript = `<div class="cardflow">
                        <div class="title-box">
                        <div class="tbold">
                        <svg width="16" height="16" fill="#23283A" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
													viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
													<g id="Layer_1">
															<path d="M39,7c0-3.309-2.691-6-6-6H7C3.691,1,1,3.691,1,7c0,2.967,2.167,5.431,5,5.91V43c0,3.309,2.691,6,6,6h31
																c3.309,0,6-2.691,6-6v-1H39V7z M3,7c0-2.206,1.794-4,4-4h21.54C27.586,4.063,27,5.462,27,7s0.586,2.937,1.54,4H7
																C4.794,11,3,9.206,3,7z M8,43V13h25v-2c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4v35v1c0,1.538,0.586,2.937,1.54,4H12
																C9.794,47,8,45.206,8,43z M46.873,44c-0.444,1.723-2.013,3-3.873,3s-3.429-1.277-3.873-3H46.873z"/>
															<rect x="12" y="17" width="4" height="2"/>
															<rect x="19" y="17" width="14" height="2"/>
															<rect x="12" y="22" width="4" height="2"/>
															<rect x="19" y="22" width="14" height="2"/>
															<rect x="12" y="27" width="4" height="2"/>
															<rect x="19" y="27" width="14" height="2"/>
															<rect x="12" y="32" width="4" height="2"/>
															<rect x="19" y="32" width="14" height="2"/>
															<rect x="12" y="37" width="4" height="2"/>
															<rect x="19" y="37" width="14" height="2"/>
															<rect x="12" y="42" width="4" height="2"/>
															<rect x="19" y="42" width="14" height="2"/>
													</g>
												</svg> RunScript</div>
                        <div class="title text-truncate">Script Name</div> </div>\n
                        <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Always</td></tr></table> </div>
                    `;
        this.editor.addNode(
          "runscript",
          1,
          1,
          pos_x,
          pos_y,
          "runscript",
          {},
          runscript
        );
        break;
      case "integrationhub":
        var integrationhub = `<div class="cardflow">
                <div class="title-box">
                <div class="tbold">

                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
<path id="cloud-pak--for-integration_1_" d="M16,31.36c-0.059,0-0.117-0.015-0.171-0.043l-13-7C2.713,24.254,2.64,24.133,2.64,24V8
	c0-0.132,0.073-0.254,0.189-0.317l13-7c0.107-0.058,0.234-0.058,0.342,0l13,7C29.287,7.746,29.36,7.868,29.36,8v16
	c0,0.133-0.073,0.254-0.189,0.317l-13,7C16.117,31.346,16.059,31.36,16,31.36z M3.36,23.785L16,30.591l12.64-6.806V8.215L16,1.409
	L3.36,8.215V23.785z M21.172,24.36c-0.852,0-1.652-0.332-2.255-0.934l-5.171-5.172l0.509-0.51l5.172,5.172
	c0.466,0.467,1.086,0.723,1.745,0.723c1.361,0,2.468-1.106,2.468-2.468c0-0.659-0.256-1.279-0.723-1.745l-2.172-2.172l0.51-0.509
	l2.172,2.171c0.602,0.603,0.934,1.403,0.934,2.255C24.36,22.93,22.93,24.36,21.172,24.36z M10.829,24.36
	c-1.758,0-3.188-1.431-3.188-3.188c0-0.853,0.332-1.653,0.934-2.255l4.171-4.171l0.509,0.509l-4.171,4.172
	c-0.466,0.466-0.723,1.086-0.723,1.745c0,1.361,1.107,2.468,2.469,2.468c0.66,0,1.279-0.256,1.746-0.723l2.171-2.172l0.509,0.51
	l-2.171,2.172C12.481,24.028,11.681,24.36,10.829,24.36z M21,22.36c-0.75,0-1.36-0.61-1.36-1.36c0-0.244,0.065-0.475,0.179-0.673
	l-2.845-2.845c-0.28,0.185-0.614,0.292-0.974,0.292c-0.978,0-1.774-0.796-1.774-1.774c0-0.359,0.107-0.694,0.292-0.974l-2.845-2.845
	C11.474,12.295,11.245,12.36,11,12.36c-0.75,0-1.36-0.61-1.36-1.36S10.25,9.64,11,9.64s1.36,0.61,1.36,1.36
	c0,0.245-0.065,0.474-0.178,0.673l2.845,2.845c0.28-0.185,0.614-0.292,0.974-0.292c0.978,0,1.774,0.796,1.774,1.774
	c0,0.359-0.107,0.694-0.292,0.974l2.845,2.845c0.198-0.113,0.429-0.179,0.673-0.179c0.75,0,1.36,0.61,1.36,1.36
	S21.75,22.36,21,22.36z M21,20.36c-0.353,0-0.64,0.287-0.64,0.64s0.287,0.64,0.64,0.64s0.64-0.287,0.64-0.64S21.353,20.36,21,20.36z
	 M16,14.946c-0.581,0-1.054,0.473-1.054,1.054c0,0.581,0.473,1.054,1.054,1.054c0.581,0,1.054-0.473,1.054-1.054
	S16.581,14.946,16,14.946z M11,10.36c-0.353,0-0.64,0.287-0.64,0.64s0.287,0.64,0.64,0.64s0.64-0.287,0.64-0.64
	S11.353,10.36,11,10.36z M19.255,17.255l-0.51-0.509l4.172-4.171c0.466-0.467,0.723-1.086,0.723-1.746
	c0-1.361-1.106-2.469-2.468-2.469c-0.659,0-1.279,0.257-1.745,0.723l-2.172,2.171l-0.509-0.509l2.171-2.171
	c0.603-0.602,1.402-0.934,2.255-0.934c1.758,0,3.188,1.43,3.188,3.188c0,0.851-0.332,1.652-0.934,2.254L19.255,17.255z
	 M10.746,15.254l-2.171-2.171c-0.602-0.602-0.934-1.402-0.934-2.254c0-1.758,1.43-3.188,3.188-3.188
	c0.852,0,1.652,0.332,2.254,0.934l5.172,5.171l-0.51,0.509l-5.171-5.171c-0.466-0.466-1.086-0.723-1.746-0.723
	c-1.361,0-2.469,1.107-2.469,2.469c0,0.66,0.257,1.279,0.723,1.746l2.171,2.171L10.746,15.254z" fill="#23283A"/></svg>
                 &nbsp;Integration Hub</div>
                <div class="title text-truncate">Integration Hub</div> </div>\n
                <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">Always</td></tr></table></div>
            `;
        this.editor.addNode(
          "integrationhub",
          1,
          1,
          pos_x,
          pos_y,
          "integrationhub",
          {},
          integrationhub
        );
        break;

      default:
    }
  }

  slideToggle() {
    let element = document.querySelector('[id="kt_aside_toggle"]');
    console.log(element.classList.contains("active"));
    if (element.classList.contains("active")) {
      document.body.classList.remove("aside-minimize");
      element.classList.remove("active");
    } else {
      document.body.classList.add("aside-minimize");
      element.classList.add("active");
    }
  }

  setPopup() {
    var query = "";

    $(document)
      .off(
        "dblclick",
        ".approval, .approval, .assign,  .sendemail, .approvalreport, .conditionalbranch, .ifelse, .mergebranches, .split, .runscript, .integrationhub, .endflow"
      )
      .on(
        "dblclick",
        ".approval, .approval, .assign,  .sendemail, .approvalreport, .conditionalbranch, .ifelse, .mergebranches, .split, .runscript, .integrationhub, .endflow",
        function (e) {
          let classname = e.currentTarget.getAttribute("class");
          classname = classname.replace("drawflow-node", "");
          classname = classname.replace("selected", "");
          this.elementdivid = e.currentTarget.getAttribute("id");
          this.ModalSwitch(classname);
        }.bind(this)
      );

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      $(document)
        .off(
          ".approval, .approval, .assign,.sendemail,.approvalreport,.conditionalbranch,.ifelse,.mergebranches,.split,.runscript,.integrationhub, .endflow"
        )
        .on(
          "click",
          ".approval, .approval, .assign,.sendemail,.approvalreport,.conditionalbranch,.ifelse,.mergebranches,.split,.runscript,.integrationhub, .endflow",
          function (e) {
            let classname = e.currentTarget.getAttribute("class");
            classname = classname.replace("drawflow-node", "");
            classname = classname.replace("selected", "");
            this.elementdivid = e.currentTarget.getAttribute("id");
            this.ModalSwitch(classname);
          }.bind(this)
        );
    }

    $(document)
      .off(
        "click",
        ".beginform, .approval, .assign, .sendemail, .approvalreport, .conditionalbranch, .ifelse, .mergebranches, .split, .runscript, .integrationhub, .endflow"
      )
      .on(
        "click",
        ".beginform, .approval, .assign, .sendemail, .approvalreport, .conditionalbranch, .ifelse, .mergebranches, .split, .runscript, .integrationhub, .endflow",
        function (e) {
          let classname = e.currentTarget.getAttribute("class");
          classname = classname.replace("drawflow-node", "");
          classname = classname.replace("selected", "");
          this.api.setDeleteNodeName(classname);
        }.bind(this)
      );
  }

  ModalSwitch(classname) {
    switch (classname.trim()) {
      case "beginform":
        this.api
          .getBeginDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.beginflow_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.beginflow_data[0]._id;
              this.ruledetails = res.beginflow_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "approval":
        this.api
          .getApprovalDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.approval_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.approval_data[0]._id;
              this.ruledetails = res.approval_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "assign":
        this.api
          .getAssignDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.assign_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.assign_data[0]._id;
              this.ruledetails = res.assign_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "sendemail":
        this.api
          .getEmailDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.email_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.email_data[0]._id;
              this.ruledetails = res.email_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "approvalreport":
        this.api
          .getappRptDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.approvalReport_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.approvalReport_data[0]._id;
              this.ruledetails = res.approvalReport_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "conditionalbranch":
        this.api
          .getCondBranchDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.condbranch_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.condbranch_data[0]._id;
              this.ruledetails = res.condbranch_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "ifelse":
        this.api
          .getIfElseDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.ifelse_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.ifelse_data[0]._id;
              this.ruledetails = res.ifelse_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "mergebranches":
        this.api
          .getMergeBranchesDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.mergebranches_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.mergebranches_data[0]._id;
              this.ruledetails = res.mergebranches_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "split":
        this.api
          .getSplitDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.split_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.split_data[0]._id;
              this.ruledetails = res.split_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "runscript":
        this.api
          .getRunScriptDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.runscript_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.runscript_data[0]._id;
              this.ruledetails = res.runscript_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;
      case "integrationhub":
        this.api
          .getIntegrationHubDetails(this.workflowid, this.elementdivid)
          .subscribe((res: any) => {
            if (res.integrationhub_data[0] === undefined) {
              this.ruleid = 0;
              this.QFFlowDialog(classname.trim(), "0", "");
            } else {
              this.ruleid = res.integrationhub_data[0]._id;
              this.ruledetails = res.integrationhub_data[0];
              this.QFFlowDialog(
                classname.trim(),
                this.ruleid,
                this.ruledetails
              );
            }
          });
        break;

      default:
        break;
    }
  }

  QFFlowDialog(query, _ruleid: any, _ruledetails: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.minHeight = "calc(100vh - 90px)";
    dialogConfig.height = "auto";
    dialogConfig.disableClose = true;
    let strstage = "";
    this.arrstages.forEach((element) => {
      if (element.rulename == _ruledetails.form_name) {
        strstage = element.stage;
      }
    });
    switch (query) {
      case "approval":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "approval",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          //workflowstage: this.ruleid === 0 ? "" : strstage,
          workflowstage: this.ruleid === 0 ? "" : _ruledetails.stage,
          workflowStagesAll: this.workflowStages,
          slcand:
            this.ruleid === 0
              ? ""
              : JSON.parse(JSON.stringify(_ruledetails.condition)),
          userapp: this.ruleid === 0 ? false : _ruledetails.isUserApprovers,
          users: this.ruleid === 0 ? "" : _ruledetails.users,
          groupapp:
            this.ruleid === 0 ? true : _ruledetails.isUserGroupApprovers,
          groups: this.ruleid === 0 ? "" : _ruledetails.groups,
          otherapp: this.ruleid === 0 ? false : _ruledetails.isOtherApprovers,
          other: this.ruleid === 0 ? "" : _ruledetails.other,
          rule: this.ruleid === 0 ? "" : _ruledetails.rule,
          schedulelist: this.ruleid === 0 ? "" : _ruledetails.schedule,
          sign: this.ruleid === 0 ? true : _ruledetails.signature,
          autoapproval: this.ruleid === 0 ? false : _ruledetails.autoApproval,
          autoreject: this.ruleid === 0 ? false : _ruledetails.autoReject,
          ispublished: this.ispublished,
        };
        console.log(dialogConfig);
        this.dialogRef = this.dialog.open(ApprovalComponent, dialogConfig);
        break;
      case "assign":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "assign",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : strstage,
          userapp: this.ruleid === 0 ? false : _ruledetails.isUserApprovers,
          users: this.ruleid === 0 ? "" : _ruledetails.users,
          groupapp:
            this.ruleid === 0 ? true : _ruledetails.isUserGroupApprovers,
          groups: this.ruleid === 0 ? "" : _ruledetails.groups,
          isothers: this.ruleid === 0 ? false : _ruledetails.isothers,
          isotherslist: this.ruleid === 0 ? "" : _ruledetails.isotherslist,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(AssignComponent, dialogConfig);
        break;
      case "sendemail":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "sendemail",
          emailsubject: this.ruleid === 0 ? "" : _ruledetails.email_subject,
          emailcontent: this.ruleid === 0 ? "" : _ruledetails.email_content,
          sendername: this.ruleid === 0 ? "" : _ruledetails.sender_name,
          replyto: this.ruleid === 0 ? "" : _ruledetails.reply_to_email,
          erecipient:
            this.ruleid === 0
              ? ""
              : _ruledetails.recipient_type == "optOthers"
              ? _ruledetails.recipient_type
              : _ruledetails.recipient_email,
          optOthers:
            this.ruleid === 0
              ? ""
              : _ruledetails.recipient_type == "optOthers"
              ? _ruledetails.recipient_email
              : "",
          recipientType: this.ruleid === 0 ? "" : _ruledetails.recipient_type,
          ispdfattach: this.ruleid === 0 ? false : _ruledetails.pdf_attachment,
          ispdfattachtype:
            this.ruleid === 0 ? "default" : _ruledetails.pdf_type,
          ishiddenfields:
            this.ruleid === 0 ? true : _ruledetails.hide_empty_field,
          senderemail: this.ruleid === 0 ? "" : _ruledetails.sender_email,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(SendemailComponent, dialogConfig);
        break;
      case "approvalreport":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : strstage,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "approvalreport",
          emailsubject: this.ruleid === 0 ? "" : _ruledetails.email_subject,
          emailcontent: this.ruleid === 0 ? "" : _ruledetails.email_content,
          sendername: this.ruleid === 0 ? "" : _ruledetails.sender_name,
          replyto: this.ruleid === 0 ? "" : _ruledetails.reply_to_email,
          erecipient: this.ruleid === 0 ? "" : _ruledetails.recipient_email,
          ispdfattach: this.ruleid === 0 ? false : _ruledetails.pdf_attachment,
          ispdfattachtype:
            this.ruleid === 0 ? "default" : _ruledetails.pdf_type,
          ishiddenfields:
            this.ruleid === 0 ? true : _ruledetails.hide_empty_field,
          senderemail: this.ruleid === 0 ? "" : _ruledetails.sender_email,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(
          ApprovalreportComponent,
          dialogConfig
        );
        break;
      case "conditionalbranch":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "conditionalbranch",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : strstage,
          slcand:
            this.ruleid === 0
              ? ""
              : JSON.parse(JSON.stringify(_ruledetails.condition)),
          userapp: this.ruleid === 0 ? false : _ruledetails.isUserApprovers,
          users: this.ruleid === 0 ? "" : _ruledetails.users,
          groupapp:
            this.ruleid === 0 ? true : _ruledetails.isUserGroupApprovers,
          groups: this.ruleid === 0 ? "" : _ruledetails.groups,
          rule: this.ruleid === 0 ? "" : _ruledetails.rule,
          sign: this.ruleid === 0 ? true : _ruledetails.signature,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(
          ConditionalbranchComponent,
          dialogConfig
        );
        break;
      case "ifelse":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "ifelse",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : strstage,
          slcand:
            this.ruleid === 0
              ? ""
              : JSON.parse(JSON.stringify(_ruledetails.condition)),
          userapp: this.ruleid === 0 ? false : _ruledetails.isUserApprovers,
          users: this.ruleid === 0 ? "" : _ruledetails.users,
          groupapp:
            this.ruleid === 0 ? true : _ruledetails.isUserGroupApprovers,
          groups: this.ruleid === 0 ? "" : _ruledetails.groups,
          rule: this.ruleid === 0 ? "" : _ruledetails.rule,
          sign: this.ruleid === 0 ? true : _ruledetails.signature,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(IfelseComponent, dialogConfig);
        break;
      case "mergebranches":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "mergebranches",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : strstage,
          branchdata: this.ruleid === 0 ? "" : _ruledetails.branchdata,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(MergebranchesComponent, dialogConfig);
        break;
      case "split":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "split",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : strstage,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(SplitComponent, dialogConfig);
        break;
      case "runscript":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "runscript",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : strstage,
          scriptplace: this.ruleid === 0 ? "" : _ruledetails.script_place,
          runscript: this.ruleid === 0 ? "" : _ruledetails.script,
          scripttype: this.ruleid === 0 ? "" : _ruledetails.script_type,
          apimethodtype: this.ruleid === 0 ? "" : _ruledetails.method_type,
          apiurl: this.ruleid === 0 ? "" : _ruledetails.apiurl,
          apiheaders: this.ruleid === 0 ? "" : _ruledetails.apiheaders,
          apiparams: this.ruleid === 0 ? "" : _ruledetails.apiparams,
          apicolumns: this.ruleid === 0 ? "" : _ruledetails.apicolumns,
          mapcolumns: this.ruleid === 0 ? "" : _ruledetails.mapcolumns,
          apicolindex: this.ruleid === 0 ? "" : _ruledetails.apicolindex,
          paramcolumns: this.ruleid === 0 ? "" : _ruledetails.paramcolumns,
          triggerbindby: this.ruleid === 0 ? "" : _ruledetails.triggerbindby,
          ispublished: this.ispublished,
        };

        this.dialogRef = this.dialog.open(RunscriptComponent, dialogConfig);
        break;
      case "integrationhub":
        dialogConfig.data = {
          description: query,
          beginid: this.ruleid === 0 ? 0 : _ruledetails._id,
          formid: this.ruleid === 0 ? this.formid : _ruledetails.form_id,
          workflowid:
            this.ruleid === 0 ? this.workflowid : _ruledetails.workflow_id,
          nodeid: this.ruleid === 0 ? this.elementdivid : _ruledetails.node_id,
          nodetype: "integrationhub",
          workflowname: this.ruleid === 0 ? "" : _ruledetails.form_name,
          workflowstage: this.ruleid === 0 ? "" : _ruledetails.stage,
          hubObjectIds: this.ruleid === 0 ? "" : _ruledetails.hubObjectId,
          ispublished: this.ispublished,
        };
        this.dialogRef = this.dialog.open(
          IntegrationhubComponent,
          dialogConfig
        );
        break;
      default:
        break;
    }

    this.dialogRef.afterClosed().subscribe(
      (query) => {
        if (typeof query !== "undefined") {
          if (query.description == "conditionalbranch") {
            this.changeCBNodes(this);
            this.changeCbHeight(this, this.elementdivid, this.dataToImport);
          }
          if (query.description == "approval" && query.autoapproval == true) {
            this.RemoveRejectNode(this);
          }
          if (query.description == "approval" && query.autoreject == true) {
            this.RemoveApprovalNode(this);
          }
          if (
            query.description == "approval" &&
            query.autoreject == false &&
            query.autoapproval == false
          ) {
            this.checkApproval(this, query);
          }
          this.changetext(
            query.workflowname,
            query.autoapproval,
            query.autoreject
          );
        }
      },
      (error) => {
        console.error("Error while closing the dialog ", error);
      }
    );
  }

  changeCbHeight(_self, nodeid, nodename) {
    if (typeof nodename === "object") {
      const objData = nodename.drawflow.Home.data;
      Object.keys(objData).map((key) => {
        var strnode = objData[key].name;
        if (strnode == "conditionalbranch") {
          nodename = strnode;
          nodeid = "node-" + key;
        }
      });
    }
    if (nodename == "conditionalbranch") {
      let colTextSize = [];
      let divHeight = $(document)
        .find("#" + nodeid + "")
        .height();
      divHeight = Number(divHeight - 58);

      $(document)
        .find("#" + nodeid + " table > tbody tr")
        .each(function (rowindex) {
          let colText = $(this)[0].innerText;
          colText = colText.replace(/\s/g, "").trim();
          colTextSize[rowindex] = colText.length;
        });
      let rowHeight = Number(divHeight) / Number(colTextSize.length);
      let outputTop = 38;
      for (let x = 1; x <= colTextSize.length; x++) {
        if (colTextSize[x - 1] > 30) {
          if (x == 1) {
            outputTop = rowHeight / 2 + 58;
          } else {
            outputTop = outputTop + rowHeight;
          }
          $(document)
            .find("#" + nodeid + " .output_" + x + "")
            .attr(
              "style",
              "position:absolute; right: -5px;top: " +
                outputTop +
                "px !important;"
            );
        } else {
          outputTop = outputTop + 28;
          $(document)
            .find("#" + nodeid + " .output_" + x + "")
            .attr(
              "style",
              "position:absolute; right: -5px;top: " +
                outputTop +
                "px !important;"
            );
        }
      }
    }
  }

  changeCBNodes(_self) {
    if (_self.api.cbsave.length > 0) {
      let divid = _self.elementdivid;
      divid = divid.replace("node-", "");
      let delindex = 0;
      let cbOutput = "";
      $(document)
        .find("#node-" + divid + " .output")
        .each(function (index) {
          if ($(this).hasClass("output_" + (index - delindex + 1))) {
            _self.editor.removeNodeOutput(
              divid,
              "output_" + (index - delindex + 1)
            );
            delindex += 1;
          }
        });

      $(document)
        .find("#tblcb > tbody tr")
        .each(function (index) {
          let tblNodeid = $(this).closest("div").parent().parent().attr("id");
          tblNodeid = tblNodeid.replace("node-", "");
          if (Number(tblNodeid) == Number(divid)) {
            $(this).remove();
          }
        });

      var conditionalbranch = ``;
      let cbOutputIndex = 0;
      _self.api.cbsave.forEach((element) => {
        if (element[1].conditions.state == "and") {
          conditionalbranch +=
            `<tr><td class="ps-3 p-qsm-1 bg_cond">` +
            element[1].conditions.controlname +
            " " +
            element[1].conditions.condition_type +
            " " +
            element[1].conditions.value +
            `</td></tr>`;
          _self.editor.addNodeOutput(divid);
        }
      });

      $(document)
        .find("#tblcb > tbody")
        .each(function (index) {
          let tblNodeid = $(this).closest("div").parent().parent().attr("id");
          tblNodeid = tblNodeid.replace("node-", "");
          if (Number(tblNodeid) == Number(divid)) {
            $(this).append(conditionalbranch);
            cbOutput = _self.getOutputNodes(_self.workdata, divid);
            Object.entries(cbOutput).forEach((element) => {
              _self.editor.addConnection(
                divid,
                element[1]["connections"][0].node,
                element[0],
                "" + element[1]["connections"][0].output + ""
              );
            });

            // for (let x = 0; x < cbOutput.length; x++) {
            //   cbOutputIndex += 1;
            //   let itemArr = "output_" + cbOutputIndex;
            //   console.log("hello");
            //   _self.editor.addConnection(
            //     divid,
            //     cbOutput[x][itemArr].connections[0].node,
            //     itemArr,
            //     "" + cbOutput[x][itemArr].connections[0].output + ""
            //   );
            // }
          }
        });

      /*code to update the node after save */
      // let content = document.querySelector(
      //   ".drawflow-node[id='" + _self.elementdivid + "'] .cardflow"
      // );
      // _self.editor.drawflow.drawflow.Home.data[divid].html = content.outerHTML;
      // _self.editor.import(this.editor.export());
      // _self.saveworkflowdata();
    }
  }

  RemoveRejectNode(_self) {
    let divid = this.elementdivid;
    divid = divid.replace("node-", "");
    let trcount = 0;
    $(document)
      .find("#node-" + divid + " .output")
      .each(function (index) {
        if ($(this).hasClass("output_2")) {
          _self.editor.removeNodeOutput(divid, "output_2");
        }
      });
    var rowCount = $("#node-" + divid + " table >tbody tr").length;
    $(document)
      .find("#tblapproval > tbody tr")
      .each(function (index) {
        let tblNodeid = $(this).closest("div").parent().parent().attr("id");
        tblNodeid = tblNodeid.replace("node-", "");
        if (Number(tblNodeid) == Number(divid)) {
          if (trcount == 1 && rowCount > 1) {
            $(this).remove();
          }
          if (rowCount == 1) {
            $(this).remove();
            var approvalnodes = `<tr><td class="ps-3 p-qsm-1 bg_approve">Approved</td></tr>`;
            $("#node-" + divid + " table >tbody").append(approvalnodes);
          }
          trcount += 1;
        }
      });
    /*code to update the node after save */
    let content = document.querySelector(
      ".drawflow-node[id='" + this.elementdivid + "'] .cardflow"
    );
    this.editor.drawflow.drawflow.Home.data[divid].html = content.outerHTML;
    this.editor.import(this.editor.export());
    this.saveworkflowdata();
  }

  RemoveApprovalNode(_self) {
    let divid = this.elementdivid;
    divid = divid.replace("node-", "");
    let trcount = 0;
    $(document)
      .find("#node-" + divid + " .output")
      .each(function (index) {
        if ($(this).hasClass("output_2")) {
          _self.editor.removeNodeOutput(divid, "output_2");
        }
      });
    var rowCount = $("#node-" + divid + " table >tbody tr").length;
    $(document)
      .find("#tblapproval > tbody tr")
      .each(function (index) {
        let tblNodeid = $(this).closest("div").parent().parent().attr("id");
        tblNodeid = tblNodeid.replace("node-", "");
        if (Number(tblNodeid) == Number(divid)) {
          if (trcount == 0 && rowCount > 1) {
            $(this).remove();
          }
          if (rowCount == 1) {
            $(this).remove();
            var approvalnodes = `<tr><td class="ps-3 p-qsm-1 bg_rejected">Rejected</td></tr>`;
            $("#node-" + divid + " table >tbody").append(approvalnodes);
          }
          trcount += 1;
        }
      });
    /*code to update the node after save */
    let content = document.querySelector(
      ".drawflow-node[id='" + this.elementdivid + "'] .cardflow"
    );
    this.editor.drawflow.drawflow.Home.data[divid].html = content.outerHTML;
    this.editor.import(this.editor.export());
    this.saveworkflowdata();
  }

  checkApproval(_self, query) {
    let divid = this.elementdivid;
    divid = divid.replace("node-", "");
    let trcount = 0;
    console.log(
      $(document).find("#tblapproval >tbody tr").length,
      Number(divid)
    );

    let rearrangeNode = false;

    if (query.autoapproval || query.autoreject) {
      rearrangeNode = true;
    }
    if (
      $(document).find("#tblapproval >tbody tr").length == 1 &&
      !query.autoapproval &&
      !query.autoreject
    ) {
      rearrangeNode = true;
    }

    if (rearrangeNode) {
      $(document)
        .find("#tblapproval > tbody tr")
        .each(function (index) {
          let tblNodeid = $(this).closest("div").parent().parent().attr("id");
          tblNodeid = tblNodeid.replace("node-", "");
          if (Number(tblNodeid) == Number(divid)) {
            if ($(this).find("td").hasClass("bg_approve")) {
              _self.editor.removeNodeOutput(divid, "output_1");
              $(this).remove();
            }
            if ($(this).find("td").hasClass("bg_rejected")) {
              _self.editor.removeNodeOutput(divid, "output_1");
              $(this).remove();
            }
          }
        });

      var approvalnodes = `<tr><td class="ps-3 p-qsm-1 bg_approve">Approved</td></tr><tr><td class="ps-3 p-qsm-1 bg_rejected">Rejected</td></tr>`;
      $(document)
        .find("#tblapproval > tbody")
        .each(function (index) {
          let tblNodeid = $(this).closest("div").parent().parent().attr("id");
          tblNodeid = tblNodeid.replace("node-", "");
          if (query.beginid == 0) {
            if (Number(tblNodeid) == Number(divid)) {
              _self.editor.addNodeOutput(divid);
              _self.editor.addNodeOutput(divid);
              $(this).append(approvalnodes);
            }
          } else {
            if (Number(tblNodeid) == Number(divid)) {
              $(this).append(approvalnodes);
              _self.editor.addNodeOutput(divid);
              _self.editor.addNodeOutput(divid);
              let appOutput = _self.getOutputNodes(_self.workdata, divid);
              if (appOutput.output_1) {
                if (appOutput.output_1.connections.length > 0) {
                  _self.editor.addConnection(
                    divid,
                    appOutput.output_1.connections[0].node,
                    "output_1",
                    "" + appOutput.output_1.connections[0].output + ""
                  );
                }
              }

              if (appOutput.output_2) {
                if (appOutput.output_2.connections.length > 0)
                  _self.editor.addConnection(
                    divid,
                    appOutput.output_2.connections[0].node,
                    "output_2",
                    "" + appOutput.output_2.connections[0].output + ""
                  );
              }
            }
          }
        });
    }

    // if (!query.autoreject && !query.autoapproval) {
    //   let svgicon =
    //     '<svg width="16" height="16" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"';
    //   svgicon +=
    //     'viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">';
    //   svgicon += "<g>";
    //   svgicon +=
    //     '<path d="M315,120h-22.689c-1.587-4.488-3.415-8.893-5.476-13.196l16.05-16.051c5.858-5.857,5.858-15.355,0-21.213L260.46,27.114';
    //   svgicon +=
    //     "c-5.857-5.858-15.355-5.858-21.213,0l-16.051,16.05c-4.303-2.06-8.707-3.887-13.196-5.476V15c0-8.284-6.716-15-15-15h-60";
    //   svgicon +=
    //     "c-8.284,0-15,6.716-15,15v22.689c-4.488,1.588-8.893,3.415-13.195,5.475l-16.051-16.05c-5.857-5.859-15.355-5.858-21.213,0";
    //   svgicon +=
    //     "L27.114,69.54c-2.813,2.813-4.394,6.628-4.394,10.606s1.58,7.794,4.394,10.606l16.051,16.051c-2.06,4.304-3.888,8.708-5.476,13.196";
    //   svgicon +=
    //     "H15c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h22.689c1.588,4.488,3.416,8.893,5.476,13.196l-16.051,16.051";
    //   svgicon +=
    //     "c-2.813,2.813-4.394,6.628-4.394,10.606s1.58,7.794,4.394,10.606l42.428,42.426c5.858,5.859,15.355,5.857,21.213,0l16.051-16.05";
    //   svgicon +=
    //     "c4.302,2.06,8.706,3.887,13.194,5.475V315c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15v-22.689";
    //   svgicon +=
    //     "	c4.489-1.589,8.894-3.416,13.196-5.476l16.051,16.05c5.857,5.857,15.355,5.857,21.213,0l42.426-42.426";
    //   svgicon +=
    //     "c5.858-5.857,5.858-15.355,0-21.213l-16.05-16.051c2.061-4.304,3.889-8.708,5.476-13.196H315c8.284,0,15-6.716,15-15v-60";
    //   svgicon +=
    //     "	C330,126.716,323.284,120,315,120z M300,180h-18.781c-6.845,0-12.822,4.634-14.527,11.263c-2.436,9.472-6.213,18.576-11.227,27.06";
    //   svgicon +=
    //     "	c-3.482,5.894-2.533,13.396,2.308,18.237l13.294,13.294l-21.213,21.213l-13.294-13.294c-4.84-4.84-12.342-5.788-18.237-2.308";
    //   svgicon +=
    //     "c-8.479,5.011-17.584,8.788-27.062,11.228c-6.628,1.706-11.261,7.683-11.261,14.526V300h-30v-18.781";
    //   svgicon +=
    //     "	c0-6.844-4.633-12.82-11.261-14.526c-9.476-2.44-18.579-6.217-27.06-11.228c-5.895-3.482-13.396-2.533-18.237,2.308l-13.294,13.294";
    //   svgicon +=
    //     "	l-21.215-21.213l13.294-13.294c4.841-4.84,5.79-12.343,2.308-18.236c-5.011-8.481-8.789-17.587-11.228-27.063";
    //   svgicon +=
    //     "	C61.602,184.633,55.625,180,48.781,180H30v-30h18.781c6.844,0,12.82-4.633,14.526-11.261c2.439-9.476,6.217-18.581,11.228-27.063";
    //   svgicon +=
    //     "	c3.482-5.894,2.533-13.397-2.308-18.236L58.934,80.146l21.214-21.213l13.294,13.294c4.84,4.84,12.345,5.79,18.237,2.308";
    //   svgicon +=
    //     "	c8.479-5.011,17.584-8.788,27.061-11.228C145.367,61.602,150,55.625,150,48.781V30h30v18.781c0,6.844,4.633,12.82,11.261,14.526";
    //   svgicon +=
    //     "	c9.478,2.44,18.583,6.217,27.062,11.228c5.893,3.48,13.396,2.533,18.237-2.308l13.294-13.294l21.213,21.213L257.772,93.44";
    //   svgicon +=
    //     '	c-4.841,4.841-5.79,12.344-2.308,18.237c5.014,8.483,8.79,17.588,11.227,27.06c1.705,6.629,7.683,11.263,14.527,11.263H300V180z"/>';
    //   svgicon +=
    //     '<path d="M203.95,152.28l-48-30c-4.624-2.891-10.453-3.043-15.222-0.4c-4.77,2.644-7.729,7.667-7.729,13.12v60';
    //   svgicon +=
    //     "	c0,5.453,2.959,10.477,7.729,13.12c2.266,1.255,4.77,1.88,7.271,1.88c2.764,0,5.523-0.763,7.95-2.28l48-30";
    //   svgicon +=
    //     '	c4.386-2.741,7.05-7.548,7.05-12.72S208.336,155.021,203.95,152.28z"/>';
    //   svgicon +=
    //     "</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> Approval";

    //   $(document)
    //     .find("#" + this.elementdivid + " .tbold")
    //     .html(svgicon);
    // }

    /*code to update the node after save */
    let content = document.querySelector(
      ".drawflow-node[id='" + this.elementdivid + "'] .cardflow"
    );
    this.editor.drawflow.drawflow.Home.data[divid].html = content.outerHTML;
    this.editor.import(this.editor.export());
    this.saveworkflowdata();
  }
  getOutputNodes(workflowData, nodeid) {
    return workflowData.drawflow.Home.data[nodeid].outputs;
  }

  changetext(strtext, autoApproval, autoReject) {
    if (autoApproval) {
      let svgicon =
        '<svg width="16" height="16" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"';
      svgicon +=
        'viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">';
      svgicon += "<g>";
      svgicon +=
        '<path d="M315,120h-22.689c-1.587-4.488-3.415-8.893-5.476-13.196l16.05-16.051c5.858-5.857,5.858-15.355,0-21.213L260.46,27.114';
      svgicon +=
        "c-5.857-5.858-15.355-5.858-21.213,0l-16.051,16.05c-4.303-2.06-8.707-3.887-13.196-5.476V15c0-8.284-6.716-15-15-15h-60";
      svgicon +=
        "c-8.284,0-15,6.716-15,15v22.689c-4.488,1.588-8.893,3.415-13.195,5.475l-16.051-16.05c-5.857-5.859-15.355-5.858-21.213,0";
      svgicon +=
        "L27.114,69.54c-2.813,2.813-4.394,6.628-4.394,10.606s1.58,7.794,4.394,10.606l16.051,16.051c-2.06,4.304-3.888,8.708-5.476,13.196";
      svgicon +=
        "H15c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h22.689c1.588,4.488,3.416,8.893,5.476,13.196l-16.051,16.051";
      svgicon +=
        "c-2.813,2.813-4.394,6.628-4.394,10.606s1.58,7.794,4.394,10.606l42.428,42.426c5.858,5.859,15.355,5.857,21.213,0l16.051-16.05";
      svgicon +=
        "c4.302,2.06,8.706,3.887,13.194,5.475V315c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15v-22.689";
      svgicon +=
        "	c4.489-1.589,8.894-3.416,13.196-5.476l16.051,16.05c5.857,5.857,15.355,5.857,21.213,0l42.426-42.426";
      svgicon +=
        "c5.858-5.857,5.858-15.355,0-21.213l-16.05-16.051c2.061-4.304,3.889-8.708,5.476-13.196H315c8.284,0,15-6.716,15-15v-60";
      svgicon +=
        "	C330,126.716,323.284,120,315,120z M300,180h-18.781c-6.845,0-12.822,4.634-14.527,11.263c-2.436,9.472-6.213,18.576-11.227,27.06";
      svgicon +=
        "	c-3.482,5.894-2.533,13.396,2.308,18.237l13.294,13.294l-21.213,21.213l-13.294-13.294c-4.84-4.84-12.342-5.788-18.237-2.308";
      svgicon +=
        "c-8.479,5.011-17.584,8.788-27.062,11.228c-6.628,1.706-11.261,7.683-11.261,14.526V300h-30v-18.781";
      svgicon +=
        "	c0-6.844-4.633-12.82-11.261-14.526c-9.476-2.44-18.579-6.217-27.06-11.228c-5.895-3.482-13.396-2.533-18.237,2.308l-13.294,13.294";
      svgicon +=
        "	l-21.215-21.213l13.294-13.294c4.841-4.84,5.79-12.343,2.308-18.236c-5.011-8.481-8.789-17.587-11.228-27.063";
      svgicon +=
        "	C61.602,184.633,55.625,180,48.781,180H30v-30h18.781c6.844,0,12.82-4.633,14.526-11.261c2.439-9.476,6.217-18.581,11.228-27.063";
      svgicon +=
        "	c3.482-5.894,2.533-13.397-2.308-18.236L58.934,80.146l21.214-21.213l13.294,13.294c4.84,4.84,12.345,5.79,18.237,2.308";
      svgicon +=
        "	c8.479-5.011,17.584-8.788,27.061-11.228C145.367,61.602,150,55.625,150,48.781V30h30v18.781c0,6.844,4.633,12.82,11.261,14.526";
      svgicon +=
        "	c9.478,2.44,18.583,6.217,27.062,11.228c5.893,3.48,13.396,2.533,18.237-2.308l13.294-13.294l21.213,21.213L257.772,93.44";
      svgicon +=
        '	c-4.841,4.841-5.79,12.344-2.308,18.237c5.014,8.483,8.79,17.588,11.227,27.06c1.705,6.629,7.683,11.263,14.527,11.263H300V180z"/>';
      svgicon +=
        '<path d="M203.95,152.28l-48-30c-4.624-2.891-10.453-3.043-15.222-0.4c-4.77,2.644-7.729,7.667-7.729,13.12v60';
      svgicon +=
        "	c0,5.453,2.959,10.477,7.729,13.12c2.266,1.255,4.77,1.88,7.271,1.88c2.764,0,5.523-0.763,7.95-2.28l48-30";
      svgicon +=
        '	c4.386-2.741,7.05-7.548,7.05-12.72S208.336,155.021,203.95,152.28z"/>';
      svgicon +=
        "</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> Auto Approve";

      $(document)
        .find("#" + this.elementdivid + " .tbold")
        .html(svgicon);
    }
    if (autoReject) {
      let svgicon =
        '<svg width="16" height="16" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"';
      svgicon +=
        'viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">';
      svgicon += "<g>";
      svgicon +=
        '<path d="M315,120h-22.689c-1.587-4.488-3.415-8.893-5.476-13.196l16.05-16.051c5.858-5.857,5.858-15.355,0-21.213L260.46,27.114';
      svgicon +=
        "c-5.857-5.858-15.355-5.858-21.213,0l-16.051,16.05c-4.303-2.06-8.707-3.887-13.196-5.476V15c0-8.284-6.716-15-15-15h-60";
      svgicon +=
        "c-8.284,0-15,6.716-15,15v22.689c-4.488,1.588-8.893,3.415-13.195,5.475l-16.051-16.05c-5.857-5.859-15.355-5.858-21.213,0";
      svgicon +=
        "L27.114,69.54c-2.813,2.813-4.394,6.628-4.394,10.606s1.58,7.794,4.394,10.606l16.051,16.051c-2.06,4.304-3.888,8.708-5.476,13.196";
      svgicon +=
        "H15c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h22.689c1.588,4.488,3.416,8.893,5.476,13.196l-16.051,16.051";
      svgicon +=
        "c-2.813,2.813-4.394,6.628-4.394,10.606s1.58,7.794,4.394,10.606l42.428,42.426c5.858,5.859,15.355,5.857,21.213,0l16.051-16.05";
      svgicon +=
        "c4.302,2.06,8.706,3.887,13.194,5.475V315c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15v-22.689";
      svgicon +=
        "	c4.489-1.589,8.894-3.416,13.196-5.476l16.051,16.05c5.857,5.857,15.355,5.857,21.213,0l42.426-42.426";
      svgicon +=
        "c5.858-5.857,5.858-15.355,0-21.213l-16.05-16.051c2.061-4.304,3.889-8.708,5.476-13.196H315c8.284,0,15-6.716,15-15v-60";
      svgicon +=
        "	C330,126.716,323.284,120,315,120z M300,180h-18.781c-6.845,0-12.822,4.634-14.527,11.263c-2.436,9.472-6.213,18.576-11.227,27.06";
      svgicon +=
        "	c-3.482,5.894-2.533,13.396,2.308,18.237l13.294,13.294l-21.213,21.213l-13.294-13.294c-4.84-4.84-12.342-5.788-18.237-2.308";
      svgicon +=
        "c-8.479,5.011-17.584,8.788-27.062,11.228c-6.628,1.706-11.261,7.683-11.261,14.526V300h-30v-18.781";
      svgicon +=
        "	c0-6.844-4.633-12.82-11.261-14.526c-9.476-2.44-18.579-6.217-27.06-11.228c-5.895-3.482-13.396-2.533-18.237,2.308l-13.294,13.294";
      svgicon +=
        "	l-21.215-21.213l13.294-13.294c4.841-4.84,5.79-12.343,2.308-18.236c-5.011-8.481-8.789-17.587-11.228-27.063";
      svgicon +=
        "	C61.602,184.633,55.625,180,48.781,180H30v-30h18.781c6.844,0,12.82-4.633,14.526-11.261c2.439-9.476,6.217-18.581,11.228-27.063";
      svgicon +=
        "	c3.482-5.894,2.533-13.397-2.308-18.236L58.934,80.146l21.214-21.213l13.294,13.294c4.84,4.84,12.345,5.79,18.237,2.308";
      svgicon +=
        "	c8.479-5.011,17.584-8.788,27.061-11.228C145.367,61.602,150,55.625,150,48.781V30h30v18.781c0,6.844,4.633,12.82,11.261,14.526";
      svgicon +=
        "	c9.478,2.44,18.583,6.217,27.062,11.228c5.893,3.48,13.396,2.533,18.237-2.308l13.294-13.294l21.213,21.213L257.772,93.44";
      svgicon +=
        '	c-4.841,4.841-5.79,12.344-2.308,18.237c5.014,8.483,8.79,17.588,11.227,27.06c1.705,6.629,7.683,11.263,14.527,11.263H300V180z"/>';
      svgicon +=
        '<path d="M203.95,152.28l-48-30c-4.624-2.891-10.453-3.043-15.222-0.4c-4.77,2.644-7.729,7.667-7.729,13.12v60';
      svgicon +=
        "	c0,5.453,2.959,10.477,7.729,13.12c2.266,1.255,4.77,1.88,7.271,1.88c2.764,0,5.523-0.763,7.95-2.28l48-30";
      svgicon +=
        '	c4.386-2.741,7.05-7.548,7.05-12.72S208.336,155.021,203.95,152.28z"/>';
      svgicon +=
        "</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> Auto Reject";

      $(document)
        .find("#" + this.elementdivid + " .tbold")
        .html(svgicon);
    }

    if (
      !$(document)
        .find(
          "#" +
            this.elementdivid +
            " .drawflow_content_node .cardflow .title-box .title"
        )
        .hasClass("text-truncate")
    ) {
      $(document)
        .find(
          "#" +
            this.elementdivid +
            " .drawflow_content_node .cardflow .title-box .title"
        )
        .addClass("text-truncate");
    }

    $(document)
      .find("#" + this.elementdivid + " .title")
      .html(strtext);
    let divid = this.elementdivid;
    divid = divid.replace("node-", "");
    let content = document.querySelector(
      ".drawflow-node[id='" + this.elementdivid + "'] .cardflow"
    );
    this.editor.drawflow.drawflow.Home.data[divid].html = content.outerHTML;
    this.editor.import(this.editor.export());
    this.saveworkflowdata();
  }

  canDeactivate(): boolean {
    return !this.isUnsaved;
  }

  ngAfterViewInit(): void {
    this.id = document.getElementById("drawflow");
    this.editor = new Drawflow(this.id);
    QFUtil.ready(() => {
      QFLayoutHeaderTopbar.init("kt_header_mobile_topbar_toggle");
      QFLayoutHeader.init("kt_header", "kt_header_mobile");
      QFLayoutHeaderMenu.init("kt_header_menu", "kt_header_menu_wrapper");
      QFLayoutBrand.init("kt_brand");
      QFLayoutAside.init("kt_aside");
      QFLayoutAsideMenu.init("kt_aside_menu");
      if (this.asideSelfMinimizeToggle) {
        QFLayoutAsideToggle.init("kt_aside_toggle");
      }
      KTLayoutStretchedCard.init("kt_page_stretched_card");
    });

    if (this.ktHeaderMenu) {
      for (const key in this.headerMenuHTMLAttributes) {
        if (this.headerMenuHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeaderMenu.nativeElement.attributes[key] =
            this.headerMenuHTMLAttributes[key];
        }
      }
    }
    var elements = document.getElementsByClassName("drag-qformsflow");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("touchend", this.drop, false);
      elements[i].addEventListener("touchmove", this.positionMobile, false);
      elements[i].addEventListener("touchstart", this.drag, false);
    }
    this.setPopup();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
    if (this.routerLoaderTimout) {
      clearTimeout(this.routerLoaderTimout);
    }
  }
}
