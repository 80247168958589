<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Activity Properties: {{ description }}</h2>
</div>
<div *ngIf="pageLoader" class="loading">
  <img src="./assets/media/loading.gif" />
</div>

<div>
  <mat-dialog-content [formGroup]="form">
    <input hidden matInput formControlName="workflowid" />
    <input hidden matInput formControlName="formid" />
    <input hidden matInput formControlName="nodeid" />
    <div class="w-100 mb-8 mt-5">
      <div class="notice d-flex bg-secondary border-primary border p-2">
        <button
          title=""
          class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn mt-1"
          role="link"
          data-original-title="Back"
          aria-expanded="true"
        >
          <span class="sr-only">Back</span>
        </button>

        <div
          class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ms-3"
        >
          <div class="mb-3 mb-md-0 fw-bold pt-2">
            <h4 class="text-gray-900 fw-bolder">
              Workflow Activity -
              {{ description }} for [ {{ formname }} ]
            </h4>
          </div>
        </div>
      </div>
    </div>
    <ng-container>
      <mat-tab-group
        headerPosition="above"
        (selectedTabChange)="tabChanged($event)"
        #tabGroup
      >
        <mat-tab label="EMAIL">
          <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div class="col-md-12 card pt-0 mb-6 mb-xl-9">
              <div class="d-flex flex-column mb-8 fv-row mt-8">
                <div class="row">
                  <div class="col-md-12">
                    <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                      <div class="col-md-10">
                        <span class="required">Email Subject</span>
                      </div>
                      <div class="col-md-2">
                        <div class="float-md-end">
                          <div
                            class="dropdown dropdown-inline"
                            data-toggle="tooltip"
                            title="Quick actions"
                            placement="bottom-right"
                            ngbDropdown
                          >
                            <a
                              href="javascript:void(0);"
                              id="btn_form_fields"
                              class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
                              ngbDropdownToggle
                            >
                              <span
                                class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"
                              >
                                <svg
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="formFields-icon"
                                >
                                  <path
                                    d="M5.318 6.569l-1.25 1.25a1.327 1.327 0 01-2.263-.938c0-.354.138-.687.388-.937l1.25-1.25a.442.442 0 00-.625-.625l-1.25 1.25A2.195 2.195 0 00.92 6.881 2.206 2.206 0 003.13 9.09c.565 0 1.131-.216 1.562-.646l1.25-1.25a.442.442 0 00-.625-.625zM9.089 3.128c0-.59-.23-1.146-.647-1.563a2.212 2.212 0 00-3.125 0l-1.25 1.25a.442.442 0 10.625.625l1.25-1.25a1.327 1.327 0 012.263.938c0 .354-.138.687-.388.937l-1.25 1.25a.442.442 0 00.625.625l1.25-1.25c.417-.418.647-.972.647-1.562z"
                                    fill="#fff"
                                  ></path>
                                  <path
                                    d="M3.442 6.567a.44.44 0 00.625 0l2.5-2.5a.442.442 0 10-.625-.625l-2.5 2.5a.442.442 0 000 .625z"
                                    fill="#fff"
                                  ></path>
                                </svg>
                              </span>
                              Form Fields</a
                            >
                            <div
                              class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0"
                              ngbDropdownMenu
                              id="kt_menu_612d79cbee38f"
                            >
                              <div class="px-7 py-5">
                                <div
                                  class="fs-5 text-dark fw-bolder form-control form-control-solid"
                                >
                                  Form
                                </div>
                              </div>
                              <div class="separator border-gray-200"></div>
                              <div class="px-7 py-5">
                                <div
                                  class="mb-1 frmfields"
                                  *ngFor="let control of formControls"
                                  [attr.data-id]="
                                    control.controlType + '_' + control.id
                                  "
                                >
                                  <label
                                    class="form-label frm-fields fw-bold"
                                    *ngIf="
                                      control?.controlType != 'groupcontrol'
                                    "
                                    >{{ control.fieldName }}</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <tagify
                      class="form-control form-control-solid email_subject"
                      id="emailsubject"
                      name="emailsubject"
                      formControlName="emailsubject"
                      (keyup)="changeEvent($event)"
                      [settings]="tagsettings"
                    ></tagify>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column mb-8">
                <label class="fs-6 fw-bold mb-2">Email Content</label>
                <textarea
                  formControlName="emailcontent"
                  id="qf_appr_cust_email"
                  name="kt_docs_tinymce_basic"
                  class="tox-target"
                >
                </textarea>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="RECIPIENT">
          <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div class="col-md-12 card pt-0 mb-6 mb-xl-9">
              <div class="d-flex flex-column mb-8 mt-8 fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <div class="col-md-10">
                    <span class="required">Sender Name</span>
                  </div>
                </label>
                <mat-form-field appearance="outline">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    value="QForms"
                    name="sendername"
                    formControlName="sendername"
                  />
                </mat-form-field>
              </div>
              <div class="d-flex flex-column mb-8 fv-row mt-8">
                <label class="fs-6 fw-bold required">Sender Email</label>
                <div class="fs-7 fw-bold text-muted mb-3">
                  Users can receive emails using your own SMTP server instead of
                  QForm's servers.
                </div>

                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput value="" formControlName="senderemail" />
                </mat-form-field>
              </div>
              <div class="d-flex flex-column mb-8 mt-8 fv-row hide">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <div class="col-md-10">
                    <span class="required">Reply-to Email</span>
                  </div>
                </label>
                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    value="mudasir.wani@lelafe.com"
                    name="replyto"
                    formControlName="replyto"
                  />
                </mat-form-field>
              </div>
              <div class="d-flex flex-column mb-8 mt-8 fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <div class="col-md-10">
                    <span class="required">Recipient Email</span>
                  </div>
                </label>
                <div class="row">
                  <mat-radio-group>
                    <div class="col-sm-12 mb-5 mt-3">
                      <mat-radio-button
                        value="groups"
                        (change)="radioChange($event)"
                        [checked]="groupapp"
                        name="group"
                      >
                        <div class="text-left">
                          <label class="form-label fw-bold">Groups</label>
                        </div>
                      </mat-radio-button>
                      <mat-radio-button
                        value="users"
                        (change)="radioChange($event)"
                        [checked]="userapp"
                        name="group"
                      >
                        <div class="text-left">
                          <label class="form-label fw-bold">Users</label>
                        </div>
                      </mat-radio-button>
                      <mat-radio-button
                        value="others"
                        (change)="radioChange($event)"
                        [checked]="isothers"
                        name="group"
                      >
                        <div class="text-left">
                          <label class="form-label fw-bold">Others</label>
                        </div>
                      </mat-radio-button>
                    </div>
                  </mat-radio-group>
                </div>
                <div class="row">
                  <div
                    class="col-sm-6"
                    [ngClass]="groupapp ? '' : 'hide'"
                    id="ptagsgroups"
                  >
                    <div class="fv-row fv-plugins-icon-container">
                      <input
                        class="mb-5 btn_psecondary tagsgroups"
                        value="{{ groupOnEdit }}"
                        name="tagsgroups"
                      />
                    </div>
                  </div>
                  <div
                    class="col-sm-6"
                    id="ptagsuser"
                    [ngClass]="userapp ? '' : 'hide'"
                  >
                    <div class="fv-row fv-plugins-icon-container">
                      <input
                        class="mb-5 btn_psecondary tagsuser"
                        id="inputtagsuser"
                        value="{{ userOnEdit }}"
                        name="tagsuser"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6" *ngIf="isothers">
                    <div class="fv-row fv-plugins-icon-container">
                      <select
                        class="form-select btn_psecondary slcothers"
                        tabindex="-1"
                        (change)="changeRecpEmail($event)"
                      >
                        <option
                          value="formsubmitter"
                          [selected]="isotherslist == 'formsubmitter'"
                        >
                          Form Submitter
                        </option>
                        <option
                          *ngFor="let control of recipientEmail"
                          [value]="control.id"
                          [attr.data-id]="control.id"
                          [selected]="isotherslist == control.id"
                        >
                          {{ control.fieldName }}
                        </option>
                        <option
                          value="optOthers"
                          [selected]="isotherslist == 'optOthers'"
                        >
                          Others
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <mat-form-field
                  appearance="outline"
                  *ngIf="isothers && isotherslist == 'optOthers'"
                >
                  <mat-label>Email</mat-label>
                  <input matInput value="" formControlName="optOthers" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="ADVANCED">
          <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div class="col-md-12 card pt-0 mb-6 mb-xl-9">
              <div class="d-flex flex-stack mt-8 col-md-11">
                <div class="me-5">
                  <label class="fs-6 fw-bold">PDF Attachment</label>
                  <div class="fs-7 fw-bold text-muted">
                    Attach the system generated pdf of this record.
                  </div>
                </div>
                <label
                  class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="ispdfattach"
                    (change)="changePdf($event)"
                  />
                </label>
              </div>

              <div class="separator border-gray-200"></div>
              <div class="d-flex flex-stack mb-8 mt-8 col-md-11">
                <div class="me-5">
                  <label class="fs-6 fw-bold">Hide Empty Fields</label>
                  <div class="fs-7 fw-bold text-muted">
                    By enabling Hide Empty Fields option, empty fields won’t be
                    visible in received emails.
                  </div>
                </div>
                <label
                  class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="ishiddenfields"
                    checked="checked"
                  />
                </label>
              </div>
              <div class="separator border-gray-200"></div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
    <a
      *ngIf="tabSelected > 0"
      href="javascript:void(0);"
      mat-raised-button
      color="primary"
      (click)="tabBack()"
      >Back</a
    >
    <a
      *ngIf="tabSelected < 2"
      href="javascript:void(0);"
      mat-raised-button
      color="primary"
      (click)="tabNext()"
      >Next</a
    >
    <a
      *ngIf="beginid == 0 && tabSelected == 2 && !ispublished"
      href="javascript:void(0);"
      mat-raised-button
      color="primary"
      (click)="saveform('save')"
      >Save</a
    >
    <a
      *ngIf="beginid != 0 && tabSelected == 2 && !ispublished"
      href="javascript:void(0);"
      mat-raised-button
      color="primary"
      (click)="saveform('update')"
      >Update</a
    >
  </mat-dialog-actions>
</div>
