import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import $ from "jquery";
import Tagify from "../../../assets/js/tagify.min";
import { MatRadioChange } from "@angular/material/radio";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-assign",
  templateUrl: "./assign.component.html",
  styleUrls: ["./assign.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AssignComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  workflowname: string;
  workflowstage: string;
  slcand: any = [];
  userapp: boolean;
  users: any = [];
  groupapp: boolean;
  groups: any = [];
  userList: any = [];
  userGroups: any = [];
  data: any;
  formControls: any = [];
  slcUserList: any = [];
  slcUserGroups: any = [];
  panelOpenState: boolean = true;
  ispublished: boolean = false;
  formname: string;
  isothers: boolean = false;
  isotherslist: any;

  constructor(
    private fb: FormBuilder,

    private api: WorkFlowService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private dialogRef: MatDialogRef<AssignComponent>,

    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      nodeid,
      nodetype,
      workflowname,
      workflowstage,
      userapp,
      users,
      groupapp,
      groups,
      isothers,
      isotherslist,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.userapp = userapp;
    this.users = users;
    this.groupapp = groupapp;
    this.groups = groups;
    this.isothers = isothers;
    this.isotherslist = isotherslist;
    this.ispublished = ispublished;
    this.formname = localStorage.getItem("formname");

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      workflowname: [workflowname, Validators.required],
      workflowstage: [workflowstage],
      userapp: [userapp],
      users: [users],
      groupapp: [groupapp],
      groups: [groups],
      isothers: [isothers],
      isotherslist: [isotherslist],
      ispublished: [ispublished],
    });
    //if (this.workflowstage != "") {
    this.form.controls.workflowstage.disable();
    //}

    if (this.beginid != 0) {
      let newusers = this.users;
      let newgroups = this.groups;
      this.users = [];
      this.groups = [];
      newusers.forEach((element) => {
        this.users.push(element.email);
      });
      newgroups.forEach((element) => {
        this.groups.push(element.role);
      });
    }
  }

  ngOnInit(): void {
    if (this.description === "assign") {
      this.description = "Assign";
    }
    this.getUserList();
    this.getUserGroups();
    this.getControls();
  }

  getControls() {
    this.api.getControls(this.formid).subscribe((res: any) => {
      for (let i = 0; i < res.controls.length; i++) {
        if (res.controls[i].controlType == "email") {
          this.formControls.push(res.controls[i]);
        }
      }
    });
  }

  getUserList() {
    this.api.getUserlist(this.formid).subscribe((res: any) => {
      let users = res;
      const filteredUsers = users.filter(user =>
        !(user.userRoles.length === 1 && user.userRoles[0] === "BillingAdministrator")
      );

      for (let i = 0; i < filteredUsers.length; i++) {
        //this.userList.push(res[i].email);
        this.userList.push({
          value: filteredUsers[i].email,
          id: filteredUsers[i]._id,
          email: filteredUsers[i].email,
          name: filteredUsers[i].name,
        });
      }
      this.bindTagifyUserList(this.userList);
    });
  }
  getUserGroups() {
    this.api.getUserGroups(this.formid).subscribe((res: any) => {
      let usergroup = res;
      const filteredUserGroups = usergroup.filter(group => group.role != "BillingAdministrator");
      for (let i = 0; i < filteredUserGroups.length; i++) {
        //this.userGroups.push(res[i].role);
        this.userGroups.push({
          value: filteredUserGroups[i].role,
          id: filteredUserGroups[i]._id,
          role: filteredUserGroups[i].role,
          type: filteredUserGroups[i].type,
        });
      }
      this.bindTagifyUserGroups(this.userGroups);
    });
  }

  bindTagifyUserList(data) {
    new Tagify(document.querySelector('[name="tagsuser"]'), {
      whitelist: data,
      dropdown: {
        searchKeys: ["name", "email"],
        enabled: 0,
        closeOnSelect: !1,
        position: "text",
        highlightFirst: true,
      },
    });
  }
  bindTagifyUserGroups(data) {
    new Tagify(document.querySelector('[name="tagsgroups"]'), {
      whitelist: data,
      dropdown: {
        searchKeys: ["role"],
        enabled: 0,
        closeOnSelect: !1,
        position: "text",
        highlightFirst: true,
      },
    });
  }

  getTagsData() {
    var taguser = document.getElementsByTagName("tag") as HTMLCollection;
    var elmparam = document.getElementsByClassName("slcothers");
    var slcelmparam = elmparam[0] as HTMLSelectElement;
    // this.isotherslist = slcelmparam.options[slcelmparam.selectedIndex].value;
    this.slcUserList = [];
    this.slcUserGroups = [];

    for (var i = 0; i < taguser.length; i++) {
      if (taguser[i].parentElement.classList.contains("tagsuser")) {
        //this.slcUserList.push(taguser[i].getAttribute("title"));
        this.slcUserList.push({
          id: taguser[i].getAttribute("id"),
          email: taguser[i].getAttribute("title"),
          name: taguser[i].getAttribute("name"),
        });
      }
      if (taguser[i].parentElement.classList.contains("tagsgroups")) {
        //this.slcUserGroups.push(taguser[i].getAttribute("title"));
        this.slcUserGroups.push({
          id: taguser[i].getAttribute("id"),
          role: taguser[i].getAttribute("role"),
          type: taguser[i].getAttribute("type"),
        });
      }
    }
    if (this.slcUserList.length <= 0 && this.slcUserGroups.length <= 0) {
      this.isotherslist = slcelmparam.options[slcelmparam.selectedIndex].value;
    }
  }

  radioChange(event: MatRadioChange) {
    var tagsgroup = document.querySelector('[id="ptagsgroups"]');
    var tagsuser = document.querySelector('[id="ptagsuser"]');

    switch (event.value) {
      case "groups":
        tagsgroup.classList.remove("hide");
        tagsuser.classList.add("hide");
        this.groupapp = true;
        this.userapp = false;
        this.isothers = false;
        break;

      case "users":
        tagsgroup.classList.add("hide");
        tagsuser.classList.remove("hide");
        this.userapp = true;
        this.groupapp = false;
        this.isothers = false;
        break;
      case "others":
        tagsgroup.classList.add("hide");
        tagsuser.classList.add("hide");
        this.isothers = true;
        this.groupapp = false;
        this.userapp = false;
        break;

      default:
        break;
    }
  }

  slcothers(event) {
    this.isotherslist =
      event.target["options"][event.target["options"].selectedIndex].value;
  }

  saveform(formstate) {
    let validform = true;
    this.getTagsData();
    if (this.form.value.workflowname == "") {
      validform = false;
    }

    if (validform) {
      this.data = {
        beginid: this.beginid,
        workflowid: this.form.value.workflowid,
        formid: this.form.value.formid,
        nodeid: this.form.value.nodeid,
        nodetype: this.nodetype,
        formname: this.form.value.workflowname,
        stage: this.form.value.workflowstage,
        isuser: this.userapp,
        approveruser: this.userapp == true ? this.slcUserList : [],
        isgroup: this.groupapp,
        approvergroup: this.groupapp == true ? this.slcUserGroups : [],
        isothers: this.isothers,
        isotherslist: this.isotherslist,
        status: true,
        userid: this.api.getUserId(),
      };
      if (this.beginid === 0 && formstate == "save") {
        this.api.saveAssign(this.data).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success("Assignee saved successfully", "Success");
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Assignee failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      } else {
        this.api.updateAssign(this.data, this.data.beginid).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success("Asignee updated successfully", "Success");
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Assignee failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      }

      this.dialogRef.close(this.form.value);
    } else {
      this.toastr.error("Please fill all mandatory fields!", "Error!");
    }
  }

  close() {
    this.dialogRef.close();
  }
}
